import { combineReducers } from "redux";

// reducer import
import customizationReducer from "./customizationReducer";
import navigationReducer from "./navigationReducer";
import snackbarReducer from "./snackbarReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as Authentication from "./authenticationActionReducer";
import * as Admin from "./adminActionReducer";
import * as SystemSettings from "./settingActionReducer";
import * as ClubCignallMembers from "./clubCignallMembersActionReducer";
import * as ContactsFormReducer from "./contactFormReducer";
import * as Categories from "./categoriesActionReducer";
import * as Products from "./productsActionReducer";
import * as Stores from "./storeActionReducer";
import * as Regions from "./regionActionReducer";
import * as FranchiseePosts from "./franchiseePostActionReducer";
import * as Franchisee from "./franchiseeForActionReducer";
import * as PersistantReducer from "./persistantReducer";
import * as BannersReducer from "./bannersActionReducer";
import * as FranchiseeNewsReducer from "./franchiseeNewsActionReducer";
import * as NotificationTemplatesReducer from "./notificationTemplatesActionReducer";
import * as BroadcastActionReducer from "./broadcastActionReducer";
import * as ClubCignallPrizeMailer from "./clubCignallPrizeActionReducer";
import * as MembersTransfer from "./membersTransferActionReducer";

//-----------------------|| COMBINE REDUCER ||-----------------------//

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["persistent"],
};

const reducer = combineReducers({
  customization: customizationReducer,
  navigation: navigationReducer,
  snackbar: snackbarReducer,
  authentication: Authentication.reducer,
  persistent: PersistantReducer.reducer,
  admins: Admin.reducer,
  systemSettings: SystemSettings.reducer,
  clubMembers: ClubCignallMembers.reducer,
  contactForm: ContactsFormReducer.reducer,
  categories: Categories.reducer,
  products: Products.reducer,
  stores: Stores.reducer,
  regions: Regions.reducer,
  franchiseePosts: FranchiseePosts.reducer,
  franchisee: Franchisee.reducer,
  banners: BannersReducer.reducer,
  franchiseeNews: FranchiseeNewsReducer.reducer,
  templates: NotificationTemplatesReducer.reducer,
  broadcast: BroadcastActionReducer.reducer,
  clubCignallPrize: ClubCignallPrizeMailer.reducer,
  membersTransfer: MembersTransfer.reducer,
});

export default persistReducer(persistConfig, reducer);
