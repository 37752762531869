import React from "react";
import { useTheme } from "@mui/material/styles";
import logo from "assets/images/logo/cignall_logo.svg";
import logoDark from "assets/images/logo/cignall_logo_dark.svg";

function CignallLogo() {
  const theme = useTheme();
  return (
    <img
      src={theme.palette.mode === "dark" ? logoDark : logo}
      alt="Cignall Logo"
      width="150px"
      height="60px"
    />
  );
}

export default CignallLogo;
