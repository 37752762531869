import { fetchPost, fetchPut } from "utils/fetch-webapi";
import { SNACKBAR_OPEN } from "./navigationActions";

export const requestContactList = "REQUEST_CONTACT_LIST";
export const responseContactList = "RESPONSE_CONTACT_LIST";
export const requestContactId = "REQUEST_CONTACT_ID";
export const responseContactId = "RESPONSE_CONTACT_ID";
export const requestFranchiseeContactList = "REQUEST_FRANCHISEE_CONTACT_LIST";
export const responseFranchiseeContactList = "RESPONSE_FRANCHISEE_CONTACT_LIST";
export const requestFranchiseeContactId = "REQUEST_FRANCHISEE_CONTACT_ID";
export const responseFranchiseeContactId = "RESPONSE_FRANCHISEE_CONTACT_ID";
export const requestContactFormUpdate = "REQUEST_CONTACT_FORM_UPDATE";
export const responseContactFormUpdate = "RESPONSE_CONTACT_FORM_UPDATE";

export const updateContactSelectedTab = "UPDATE_CONTACT_SELECTED_TAB";

export const actionCreators = {
  getGeneralContacts: (searchType, pageNo, rowsPerPage) => async (dispatch) => {
    try {
      dispatch({ type: requestContactList });
      const [, response] = await fetchPost("/general-contacts", {
        searchType,
        pageNo,
        rowsPerPage,
      });
      dispatch({ type: responseContactList, payload: response });
    } catch (e) {
      console.log("Error while trying to request contacts list" + e);
    }
  },

  getGeneralContactsById:
    (contactFormType, contactFormId, setValue) => async (dispatch) => {
      try {
        dispatch({ type: requestContactId });
        const [, response] = await fetchPost("/general-contact", {
          contactFormType,
          contactFormId,
        });

        setValue(response && response.status ? "actioned" : "not actioned");
        dispatch({ type: responseContactId, payload: response });
      } catch (e) {
        console.log("Error while trying to request contact Id" + e);
      }
    },

  updateContactsFormStatus:
    (contactFormType, contactFormId, status, setValue) => async (dispatch) => {
      try {
        const [, response] = await fetchPut("/update/contact-form", {
          contactFormType,
          contactFormId,
          status: status === "actioned" ? true : false,
        });

        if (response && response.errorNo === 0) {
          if (contactFormType === "ContactUs") {
            dispatch(
              actionCreators.getGeneralContactsById(
                contactFormType,
                contactFormId,
                setValue
              )
            );
          }
          if (contactFormType === "Franchisee") {
            dispatch(
              actionCreators.getFranchiseeContactsById(
                contactFormType,
                contactFormId,
                setValue
              )
            );
          }
        }

        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: response.message,
          variant: "alert",
          alertSeverity: `${
            response && response.errorNo && response.errorNo > 0
              ? "error"
              : "success"
          }`,
        });
      } catch (e) {
        console.log("Error while trying to update contact list " + e);
      }
    },

  updateFranchiseeContactsFormStatus:
    (contactFormType, contactFormId, status, setValue) => async (dispatch) => {
      try {
        const [, response] = await fetchPut("/update/contact-form", {
          contactFormType,
          contactFormId,
          status: status === "enable" ? true : false,
        });

        if (response && response.errorNo === 0) {
          if (contactFormType === "Franchisee") {
            dispatch(
              actionCreators.getFranchiseeContactsById(
                contactFormType,
                contactFormId,
                setValue
              )
            );
          }
          if (contactFormType === "ContactUs") {
            dispatch(
              actionCreators.getGeneralContactsById(
                contactFormType,
                contactFormId,
                setValue
              )
            );
          }
        }

        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: response.message,
          variant: "alert",
          alertSeverity: `${
            response && response.errorNo && response.errorNo > 0
              ? "error"
              : "success"
          }`,
        });
      } catch (e) {
        console.log("Error while trying to update contact list " + e);
      }
    },

  getFranchiseeContacts:
    (searchType, pageNo, rowsPerPage) => async (dispatch) => {
      try {
        dispatch({ type: requestFranchiseeContactList });
        const [, response] = await fetchPost("/franchisee-contacts", {
          searchType,
          pageNo,
          rowsPerPage,
        });
        dispatch({ type: responseFranchiseeContactList, payload: response });
      } catch (e) {
        console.log("Error while trying to request contact list" + e);
      }
    },

  getFranchiseeContactsById:
    (contactFormType, contactFormId, setValue) => async (dispatch) => {
      try {
        dispatch({ type: requestFranchiseeContactId });
        const [, response] = await fetchPost("/franchisee-contact", {
          contactFormType,
          contactFormId,
        });

        setValue(response && response.status ? "enable" : "disable");
        dispatch({ type: responseFranchiseeContactId, payload: response });
      } catch (e) {
        console.log("Error while trying to request franchisee id" + e);
      }
    },
};

const initialState = {
  requestContacts: false,
  contactsList: null,
  requestContactById: false,
  requestUpdatedContactForm: false,
  contactsById: null,
  updatedContactForm: null,
  requestFranchiseeContactById: false,
  franchiseeContactsById: null,
  contactDetails: null,
  requestFranchiseeContacts: false,
  franchiseeContactsList: null,
  tabIndex: 0,
};

export const reducer = (state, { type, payload }) => {
  state = state || initialState;
  switch (type) {
    case requestContactList:
      return { ...state, requestContacts: true };
    case responseContactList:
      return { ...state, requestContacts: false, contactsList: payload };
    case requestFranchiseeContactList:
      return { ...state, requestFranchiseeContacts: true };
    case responseFranchiseeContactList:
      return {
        ...state,
        requestFranchiseeContacts: false,
        franchiseeContactsList: payload,
      };
    case requestContactId:
      return { ...state, requestContactById: true };
    case responseContactId:
      return { ...state, requestContactById: false, contactsById: payload };
    case requestFranchiseeContactId:
      return { ...state, requestFranchiseeContactById: true };
    case responseFranchiseeContactId:
      return {
        ...state,
        requestFranchiseeContactById: false,
        franchiseeContactsById: payload,
      };
    case requestContactFormUpdate:
      return { ...state, requestUpdatedContactForm: true };
    case responseContactFormUpdate:
      return {
        ...state,
        requestUpdatedContactForm: false,
        updatedContactForm: payload,
      };
    case updateContactSelectedTab:
      return { ...state, tabIndex: payload };
    default:
      return state;
  }
};
