import config from "./env_config";
import { systemRoles } from "system_security";

export const apiUrl = config.API_DOMAIN;
export const imageBase = config.IMAGE_BASE;
export const envName = process.env.REACT_APP_ENV_NAME.trim();

export const getInitialRoute = (adminRole) => {
  switch (adminRole) {
    case systemRoles.FranchiseeAdmin:
      return "/franchisees";
    case systemRoles.StoreRep:
      return "/stores";
    case systemRoles.SuperAdmin:
      return "/franchisees";
    default:
      return "/";
  }
};

export const extractAdminRole = (adminRoles) => {
  if (!adminRoles || adminRoles.length === 0) return "";
  else if (adminRoles.length === 1) return adminRoles[0].name;
  else return adminRoles.map((r) => r.name).join(",");
};

export const isNull = (variable, defaultValue) => {
  return typeof variable === "undefined" || !variable ? defaultValue : variable;
};
