import { toast } from "react-toastify";
import config from "env_config";

export const apiUrl = config.API_DOMAIN;
export const publicImageUrl = config.PUBLIC_IMAGE_DOMAIN;

export const saveAuthToken = (token) =>
  localStorage.setItem("cignall-auth-token", token);
export const clearAuthToken = () =>
  localStorage.removeItem("cignall-auth-token");
export const getAuthToken = () => localStorage.getItem("cignall-auth-token");

export const fetchGet = async (url) => {
  let response, error;
  try {
    const fetchTask = await fetch(apiUrl + url, {
      headers: { Authorization: getAuthToken() },
      mode: "cors",
    });
    response = await handleResponse(fetchTask);
  } catch (e) {
    error = e.message;
  }
  return [error, response];
};

export const fetchPost = async (url, body) => {
  let response, error;
  try {
    const fetchTask = await fetch(apiUrl + url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthToken(),
      },
      method: "POST",
      body: JSON.stringify(body),
      mode: "cors",
    });
    response = await handleResponse(fetchTask);
  } catch (e) {
    error = e;
  }
  return [error, response];
};

export const fetchPut = async (url, body) => {
  let response, error;
  try {
    const fetchTask = await fetch(apiUrl + url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthToken(),
      },
      method: "PUT",
      body: JSON.stringify(body),
      mode: "cors",
    });
    response = await handleResponse(fetchTask);
  } catch (e) {
    error = e.message;
  }
  return [error, response];
};

export const fetchDelete = async (url) => {
  let response, error;
  try {
    const fetchTask = await fetch(apiUrl + url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthToken(),
      },
      method: "DELETE",
      mode: "cors",
    });
    response = await handleResponse(fetchTask);
  } catch (e) {
    error = e.message;
  }
  return [error, response];
};

export function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      console.error("handleResponse::error::", response, data);
      const error = {
        status: response.status,
        statusText: response.statusText,
        dataErrors: data ? data.errors : [],
        dataErrorsTitle: data ? data.title : "",
      };
      if (response.status === 401) {
        clearAuthToken();
        window.location.reload();
      } else if (response.status === 403) {
        toast.error(data.message);
      } else if (response.status === 400) {
        console.log("ERROR-400");
        toast.error(data.message);
      } else if (response.status === 500) {
        toast.error("Internal Server Error");
      } else {
        toast.error(data.message);
      }
      return Promise.reject(error);
    }
    return data;
  });
}

export const fetchGetHtmlTemplate = async (url) => {
  let response, error;
  try {
    const fetchTask = await fetch(apiUrl + url, {
      headers: {
        Authorization: getAuthToken(),
        "Content-Type": "text/html",
      },
      mode: "cors",
    });
    response = await fetchTask.text();
  } catch (e) {
    error = e.message;
  }
  return [error, response];
};

export const uploadImage = async (url, file) => {
  if (!file) return "";
  const ext = file.name.split(".")[1];
  try {
    const formData = new FormData();
    formData.append("image", file, file.name);
    const fetchTask = await fetch(apiUrl + url, {
      headers: { Authorization: getAuthToken() },
      method: "POST",
      body: formData,
      mode: "cors",
    });
    const insertMediaResponse = await fetchTask.json();
    return [null, { fileName: insertMediaResponse.message, ext }];
  } catch (ex) {
    console.log("Error uploading media", ex);
    return [ex, null];
  }
};

export const uploadMedia = async (url, files) => {
  if (!files) return "";
  try {
    const formData = new FormData();
    [...files].forEach((file) => {
      formData.append("file", file, file.name);
    });

    const fetchTask = await fetch(apiUrl + url, {
      headers: { Authorization: getAuthToken() },
      method: "POST",
      body: formData,
      mode: "cors",
    });
    const insertMediaResponse = await fetchTask.json();
    return [null, { fileName: insertMediaResponse.message }];
  } catch (ex) {
    console.log("Error uploading media", ex);
    return [ex, null];
  }
};

export const downloadMediaFromBlob = async (mediaUri) => {
  let response, error;
  try {
    const fetchTask = await fetch(
      apiUrl + "/authmedia/?fileUrl=" + encodeURIComponent(mediaUri),
      {
        headers: {
          Authorization: getAuthToken(),
        },
        mode: "cors",
      }
    );
    return await fetchTask.blob();
  } catch (e) {
    error = e.message;
  }
  return [error, response];
};

export const isValidURL = (str) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

/*
 * Copied from https://codesandbox.io/s/6ywrkn3jl3
 * */
export function throttle(callback, interval) {
  let debounceTimeoutId;
  return function (...args) {
    clearTimeout(debounceTimeoutId);
    debounceTimeoutId = setTimeout(() => callback.apply(this, args), interval);
  };
}
