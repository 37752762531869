import { fetchGet, fetchPost, fetchPut } from "utils/fetch-webapi";
import { SNACKBAR_OPEN } from "./navigationActions";
import { isValidInt } from "utils/input-sanitize";

const requestBannersList = "REQUEST_BANNERS_LIST";
const responseBannersList = "RESPONSE_BANNERS_LIST";
const requestBannersTypesAndTargets = "REQUEST_BANNERS_TYPES_AND_TARGETS";
const responseBannersTypesAndTargets = "RESPONSE_BANNERS_TYPES_AND_TARGETS";
const requestBannerById = "REQUEST_BANNER_BY_ID";
const responseBannerById = "RESPONSE_BANNER_BY_ID";

const requestBannersImage = "REQUEST_BANNER_IMAGE";

export const updateSelectedBanner = "UPDATE_SELECTED_BANNER";
export const updateSelectedTarget = "UPDATE_SELECTED_TARGET";
export const updateSelectedStateTarget = "UPDATE_SELECTED_STATE_TARGET";
export const updateSelectedTab = "UPDATE_SELECTED_TAB";
const updateBannerNotFound = "UPDATE_BANNERS_NOT_FOUND";

export const actionCreators = {
  getAllBanners:
    (searchTerm, pageNo, rowsPerPage, searchType) => async (dispatch) => {
      try {
        dispatch({ type: requestBannersList });
        const [, response] = await fetchPost("/banners", {
          searchTerm,
          pageNo,
          rowsPerPage,
          searchType,
        });
        dispatch({ type: responseBannersList, payload: response });
      } catch (e) {
        console.log("Error while trying to request banner" + e);
      }
    },

  getBannersTypeAndTargets: () => async (dispatch) => {
    try {
      dispatch({ type: requestBannersTypesAndTargets });
      const [, response] = await fetchGet("/banners-target-types");
      dispatch({ type: responseBannersTypesAndTargets, payload: response });
    } catch (e) {
      console.log("Error while trying to fetch banners types and targets", e);
    }
  },

  getBannerByBannerId: (bannerId, setImageURL) => async (dispatch) => {
    try {
      if (isValidInt(bannerId)) {
        dispatch({ type: updateBannerNotFound, payload: false });
        dispatch({ type: requestBannerById, payload: true });
        const [, response] = await fetchGet(`/banner/${bannerId}`);
        if (response && response.banner) {
          const getTypes = response.configTypes.filter(
            (type) =>
              type.configChoiceId === response.banner.bannerTypeConfigChoiceId
          )[0];
          if (getTypes.choiceValue === "Image Only") {
            dispatch({
              type: updateSelectedBanner,
              payload: response.banner.bannersBlob,
            });
          }
          setImageURL(response.banner.imageURL);

          const getTarget = response.configTargets.filter(
            (target) =>
              target.configChoiceId ===
              response.banner.bannerTargetConfigChoiceId
          )[0];
          if (getTarget.choiceValue === "By Region") {
            const target = response.bannerTargets.map((t) =>
              parseInt(t.target)
            );
            dispatch({
              type: updateSelectedTarget,
              payload: target,
            });
          }
          if (getTarget.choiceValue === "By State") {
            const target = response.bannerTargets.map((t) => t.target);
            dispatch({
              type: updateSelectedStateTarget,
              payload: target,
            });
          }
        }
        dispatch({ type: responseBannerById, payload: response });
      } else {
        dispatch({ type: updateBannerNotFound, payload: true });
      }
    } catch (e) {
      dispatch({ type: requestBannerById, payload: false });
      dispatch({ type: updateBannerNotFound, payload: false });
      console.log("Error while trying to fetch banner by banner Id", e);
    }
  },

  resetInitialState: () => (dispatch) => {
    dispatch({
      type: updateSelectedBanner,
      payload: null,
    });
    dispatch({
      type: updateSelectedTarget,
      payload: [],
    });
    dispatch({
      type: updateSelectedStateTarget,
      payload: [],
    });
  },

  createNewBanner:
    (postData, setImageURL, setSubmitting, resetForm) => async (dispatch) => {
      setSubmitting(false);
      try {
        const [, response] = await fetchPost("/banner", { ...postData });
        if (response && response.errorNo === 0) {
          setImageURL(null);
          resetForm();
          dispatch(actionCreators.resetInitialState());
        }
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: response.message,
          variant: "alert",
          alertSeverity: `${response.errorNo > 0 ? "error" : "success"}`,
        });
      } catch (e) {
        setSubmitting(false);
        console.log("Error while trying to create a new banner", e);
      }
    },

  updateBanner:
    (postData, setImageURL, setSubmitting, navigateToParentPage) =>
    async (dispatch) => {
      try {
        const [, response] = await fetchPut("/banner", { ...postData });
        if (response && response.errorNo === 0) {
          setImageURL(null);

          dispatch(actionCreators.resetInitialState());
          navigateToParentPage();
        }
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: response.message,
          variant: "alert",
          alertSeverity: `${response.errorNo > 0 ? "error" : "success"}`,
        });
      } catch (e) {
        setSubmitting(false);
        console.log("Error while trying to create a new banner", e);
      }
    },
};

const initialState = {
  requestBanners: false,
  bannersList: null,
  gettingBannersTypesAndTargets: false,
  bannersTypesTargets: null,
  gettingBannerImage: false,
  selectedBanner: null,
  selectedTargets: [],
  selectedStateTargets: [],
  gettingBannerById: false,
  bannerNotFound: false,
  bannerDetails: null,
  tabIndex: 0,
};

export const reducer = (state, { type, payload }) => {
  state = state || initialState;
  switch (type) {
    case requestBannersList:
      return { ...state, requestBanners: true };
    case responseBannersList:
      return { ...state, requestBanners: false, bannersList: payload };
    case requestBannersTypesAndTargets:
      return { ...state, gettingBannersTypesAndTargets: true };
    case responseBannersTypesAndTargets:
      return {
        ...state,
        gettingBannersTypesAndTargets: false,
        bannersTypesTargets: payload,
      };
    case updateSelectedTab:
      return { ...state, tabIndex: payload };
    case updateSelectedTarget:
      return { ...state, selectedTargets: payload };
    case updateSelectedStateTarget:
      return { ...state, selectedStateTargets: payload };
    case requestBannersImage:
      return { ...state, gettingBannerImage: true };
    case updateSelectedBanner:
      return { ...state, gettingBannerImage: false, selectedBanner: payload };
    case requestBannerById:
      return { ...state, gettingBannerById: payload };
    case responseBannerById:
      return {
        ...state,
        gettingBannerById: false,
        bannerDetails: payload,
        bannerNotFound: payload === null || payload.banner === undefined,
      };
    case updateBannerNotFound:
      return { ...state, bannerNotFound: payload };
    default:
      return state;
  }
};
