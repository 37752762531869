import React from "react";

// third-party library
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import qs from "query-string";

// project imports
import MainRoutes from "./MainRoutes";
import LoginRoutes from "./LoginRoutes";

//-----------------------|| ROUTING RENDER ||-----------------------//
const AppRoutes = () => {
  const location = useLocation();
  const urlList = ["/forgot-password", "/reset-password/:token"];
  const urlLogin = "/login";
  const { currentAdmin } = useSelector((state) => state.authentication);

  const loggedIn = () => {
    return (
      currentAdmin &&
      typeof currentAdmin === "object" &&
      currentAdmin.identity !== 0
    );
  };
  const val = qs.parse(location.search);

  if (
    loggedIn() &&
    val &&
    val.redirect &&
    location.search.startsWith("?redirect")
  ) {
    return <Navigate to={val.redirect} replace />;
  }

  const showLoginRoutes = () => {
    //console.log("showLoginRoutes" + JSON.stringify(location));
    if (!loggedIn()) {
      //console.log("Not in. Show login" + JSON.stringify(location.pathname));
      return true;
    } else if (location && urlList.indexOf(location.pathname) > -1) {
      //console.log("Access public LR" + JSON.stringify(location.pathname));
      return true;
    } else if (loggedIn() && location.pathname.startsWith(urlLogin)) {
      //console.log("Already in. No LR" + JSON.stringify(location.pathname));
      return false;
    }

    return false;
  };

  return (
    <>
      {/* Routes for authentication pages */}
      {/* <AuthenticationRoutes /> */}
      <React.Fragment>
        {/* Route for login */}
        {showLoginRoutes() ? (
          <LoginRoutes />
        ) : (
          <MainRoutes admin={currentAdmin} />
        )}

        {/* Routes for main layouts */}
      </React.Fragment>
    </>
  );
};

export default AppRoutes;
