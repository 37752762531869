import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { DateTime } from "luxon";

const detectionOptions = {
  order: ["queryString", "path", "cookie"],
  caches: [],
  lookupFromPathIndex: 0,
  lookupQuerystring: "lng",
  lookupCookie: "meine_nanny_i18next",
};

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    /* i18n.resolvedLanguage */
    //lng: "en-AU",
    detection: detectionOptions,
    supportedLngs: ["en", "en-AU", "en-GB", "fr", "zh", "zh-CN"],
    fallbackLng: "en-AU",
    debug: process.env.NODE_ENV === "development",
  });

// new usage
// eslint-disable-next-line no-unused-vars
i18n.services.formatter.add("DATE_HUGE", (value, lng, options) => {
  return DateTime.fromJSDate(value)
    .setLocale(lng)
    .toLocaleString(DateTime.DATE_HUGE);
});

export default i18n;
