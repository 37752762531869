import { SNACKBAR_OPEN } from "./navigationActions";
import qs from "query-string";
import { getInitialRoute } from "../common";
import { isAnonymousRoute } from "routes/AnonymousRoutes";
import { fetchPost, saveAuthToken, getAuthToken } from "utils/fetch-webapi";
import env_config from "../env_config";

const requestCurrentAdmin = "REQUEST_CURRENT_ADMIN";
export const responseCurrentAdmin = "RESPONSE_CURRENT_ADMIN";
const requestAdminLogin = "REQUEST_ADMIN_LOGIN";
const responseAdminLogin = "RESPONSE_ADMIN_LOGIN";
const requestForgotPassword = "REQUEST_FORGOT_PASSWORD";
const responseForgotPassword = "RESPONSE_FORGOT_PASSWORD";
const requestChangePassword = "REQUEST_CHANGE_PASSWORD";
const responseChangePassword = "RESPONSE_CHANGE_PASSWORD";

export const actionCreators = {
  // calling /me
  requestCurrentAdmin: (location, navigate) => async (dispatch) => {
    dispatch({ type: requestCurrentAdmin });
    let response = await fetch(`${env_config.API_DOMAIN}/me`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthToken(),
      },
    });
    if (!response.ok && !isAnonymousRoute(location.pathname)) {
      navigate(`/login`, { replace: true });
    }
    if (response.status === 200) {
      response = await response.json();
    } else {
      response = "Unknown";
    }
    dispatch({ type: responseCurrentAdmin, payload: response });
  },
  // calling /authenticate
  requestAdminLogin:
    (location, navigate, values, setSubmitting) => async (dispatch) => {
      try {
        dispatch({ type: requestAdminLogin });
        const [, response] = await fetchPost("/authenticate", { ...values });
        if (response && response.errorNo > 0) {
          dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: response.message,
            variant: "alert",
            alertSeverity: "error",
          });
        } else {
          saveAuthToken(response.authToken);

          dispatch(actionCreators.requestCurrentAdmin(location, navigate));

          dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: "Login Success",
            variant: "alert",
            alertSeverity: "success",
          });
          setTimeout(() => {
            if (location && location.search) {
              const val = qs.parse(location.search);
              if (val && val.redirect) {
                window.location.href = window.location.origin + val.redirect;
                return;
              }
            }
            navigate(getInitialRoute(response.adminRole), { replace: true });
          }, 500);
        }
        dispatch({ type: responseAdminLogin, payload: response });
        setSubmitting(false);
      } catch (error) {
        console.log({ error });
        setSubmitting(false);
      }
    },

  forgotPassword: (email, setSubmitting) => async (dispatch) => {
    try {
      const [, response] = await fetchPost("/forgot-password", { email });
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: response.message,
        variant: "alert",
        alertSeverity: `${response.errorNo > 0 ? "error" : "success"}`,
      });
      if (setSubmitting) setSubmitting(false);
    } catch (e) {
      if (setSubmitting) setSubmitting(false);
    }
  },

  changePassword: (values, setSubmitting, resetForm) => async (dispatch) => {
    try {
      const [, response] = await fetchPost("/change-password", {
        ...values,
      });

      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: response.message,
        variant: "alert",
        alertSeverity: response.errorNo > 0 ? "error" : "success",
      });
      if (response && response.errorNo === 0) {
        resetForm();
      }
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
    }
  },

  resetPassword:
    (values, setSubmitting, resetForm, setIsPasswordReset) =>
    async (dispatch) => {
      try {
        const [, response] = await fetchPost("/reset-password", {
          ...values,
        });

        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: response.message,
          variant: "alert",
          alertSeverity: response.errorNo > 0 ? "error" : "success",
        });
        if (response && response.errorNo === 0) {
          resetForm();
          setIsPasswordReset(true);
        }
        setSubmitting(false);
      } catch (e) {
        setSubmitting(false);
      }
    },
};

const initialState = {
  requestLogin: false,
  users: null,
  gettingCurrentAdmin: false,
  currentAdmin: null,
  requestingForgotPassword: false,
  forgotPasswordResponse: null,
  requestingChangePassword: false,
};

export const reducer = (state, { type, payload }) => {
  state = state || initialState;
  switch (type) {
    case requestCurrentAdmin:
      return { ...state, gettingCurrentAdmin: true };
    case responseCurrentAdmin:
      return { ...state, gettingCurrentAdmin: false, currentAdmin: payload };
    case requestAdminLogin:
      return { ...state, requestLogin: true };
    case responseAdminLogin:
      return { ...state, requestLogin: false, users: payload };
    case requestForgotPassword:
      return {
        ...state,
        requestingForgotPassword: true,
        forgotPasswordResponse: null,
      };
    case responseForgotPassword:
      return {
        ...state,
        requestingForgotPassword: false,
        forgotPasswordResponse: payload,
      };
    case requestChangePassword:
      return {
        ...state,
        requestingChangePassword: true,
      };
    case responseChangePassword:
      return {
        ...state,
        requestingChangePassword: false,
      };
    default:
      return state;
  }
};
