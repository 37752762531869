export const SET_MENU = "SET_MENU";

// action - navigation reducer
export const MENU_TOGGLE = "@navigation/MENU_TOGGLE";
export const MENU_OPEN = "@navigation/MENU_OPEN";

// action - snackbar
export const SNACKBAR_OPEN = "@snackbar/SNACKBAR_OPEN";

export function convertActionToMenuOpen(action) {
  return {
    id: action.id,
    title: action.title,
    type: action.type,
    url: action.url,
  };
}

export function getUserPreferencesMenuOpen() {
  return {
    id: "UserPreferences",
    title: "User Preferences",
    type: "item",
    url: "/user-preferences",
  };
}

export function getChangePasswordMenuOpen() {
  return {
    id: "changePassword",
    title: "Change Password",
    type: "item",
    url: "/change-password",
  };
}
