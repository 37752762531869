import React from "react";

// mui-material
import { styled } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";

// style constant
const DivRoot = styled("div")(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 1301,
  width: "100%",

  "& > * + *": {
    marginTop: theme.spacing(2),
  },
}));

//-----------------------|| Loader ||-----------------------//

const Loader = () => {
  return (
    <DivRoot>
      <LinearProgress />
    </DivRoot>
  );
};

export default Loader;
