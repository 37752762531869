import { fetchGet, fetchPut } from "utils/fetch-webapi";
import { SNACKBAR_OPEN } from "./navigationActions";

export const requestNotificationTemplates = "REQUEST_NOTIFICATION_TEMPLATES";
export const responseNotificationTemplates = "RESPONSE_NOTIFICATION_TEMPLATES";
export const requestUpdateTemplate = "REQUEST_UPDATE_TEMPLATE";
export const responseUpdateTemplate = "RESPONSE_UPDATE_TEMPLATE";

const initialState = {
  loadingTemplates: false,
  templates: null,
  updatingTemplates: [],
};

export const actionCreators = {
  getNotificationTemplates: () => async (dispatch) => {
    dispatch({ type: requestNotificationTemplates });
    const [, response] = await fetchGet("/templates");
    dispatch({ type: responseNotificationTemplates, response });
  },
  updateNotificationTemplate:
    (notificationTypeCode, notificationTemplateId, postData, setSubmitting) =>
    async (dispatch) => {
      const updatingCalculatedId = `${notificationTypeCode}:${notificationTemplateId}`;
      dispatch({ type: requestUpdateTemplate, updatingCalculatedId });
      const [, response] = await fetchPut(
        `/template/${notificationTypeCode}/${notificationTemplateId}`,
        { template: postData }
      );

      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: response.message || "Updated successfully",
        variant: "alert",
        alertSeverity: `${
          response && response.errorNo && response.errorNo > 0
            ? "error"
            : "success"
        }`,
      });
      dispatch({
        type: responseUpdateTemplate,
        response,
        updatingCalculatedId,
      });
      setSubmitting(false);
    },
};

export const reducer = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case requestNotificationTemplates:
      return { ...state, loadingTemplates: true };
    case responseNotificationTemplates:
      return { ...state, loadingTemplates: false, templates: action.response };
    case requestUpdateTemplate:
      return {
        ...state,
        updatingTemplates: [
          ...state.updatingTemplates,
          action.updatingCalculatedId,
        ],
      };
    case responseUpdateTemplate:
      // eslint-disable-next-line no-case-declarations
      let existing;
      existing = state.updatingTemplates;
      existing = existing.filter((i) => i !== action.updatingCalculatedId);
      // eslint-disable-next-line no-case-declarations
      const newTemplates = state.templates.map((t) => {
        if (
          t.notificationTypeCode ===
          action.response.notificationType.notificationTypeCode
        )
          return action.response.notificationType;
        return t;
      });
      return {
        ...state,
        updatingTemplates: [...existing],
        templates: newTemplates,
      };
    default:
      return state;
  }
};
