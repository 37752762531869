import React, { lazy } from "react";

// third-party library
import { Navigate, Route, Routes } from "react-router-dom";
// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import { systemModules } from "system_modules";
import { systemRoles } from "system_security";
import { getInitialRoute } from "common";

const LazyChangePassword = Loadable(
  lazy(
    () => import("../views/pages/authentication/change-password/ChangePassword")
  )
);

const LazyUserPreferences = Loadable(
  lazy(() => import("../views/app-components/UserPreferences"))
  //lazy(() => import("../views/application/users/account-profile/Profile1"))
);

const LazyAdmin = Loadable(
  lazy(() => import("../views/app-components/Admin/AdminPage"))
);
const LazyCreateEditAdmin = Loadable(
  lazy(() => import("../views/app-components/Admin/CreateEditAdmin"))
);

const LazyClubCignallMembersHomePage = Loadable(
  lazy(
    () =>
      import(
        "../views/app-components/ClubCignallMembers/ClubCignallMembersHomePage"
      )
  )
);
const LazyClubCignallMembersAddPage = Loadable(
  lazy(
    () =>
      import(
        "../views/app-components/ClubCignallMembers/ClubCignallMembersAddPage"
      )
  )
);
const LazyCategories = Loadable(
  lazy(() => import("../views/app-components/Categories"))
);
const LazyCategoriesAddEdit = Loadable(
  lazy(() => import("../views/app-components/Categories/CategoriesAddEdit"))
);

const LazyProducts = Loadable(
  lazy(() => import("../views/app-components/Products"))
);
const LazyProductAddEdit = Loadable(
  lazy(() => import("../views/app-components/Products/ProductAddEdit"))
);
const LazyBrandAddEdit = Loadable(
  lazy(() => import("../views/app-components/Brands/BrandAddEdit"))
);

const LazyFranchisee = Loadable(
  lazy(() => import("../views/app-components/Franchisee"))
);

const LazyFranchiseeAddEdit = Loadable(
  lazy(() => import("../views/app-components/Franchisee/FranchiseeAddEdit"))
);

const LazyStores = Loadable(
  lazy(() => import("../views/app-components/Stores"))
);

const LazyStoresAddEdit = Loadable(
  lazy(() => import("../views/app-components/Stores/StoreAddEdit"))
);

const LazyBanners = Loadable(
  lazy(() => import("../views/app-components/Banners"))
);

const LazyBannersAddEdit = Loadable(
  lazy(() => import("../views/app-components/Banners/BannersAddEdit"))
);
// regions related components

const LazyRegions = Loadable(
  lazy(() => import("../views/app-components/Regions"))
);

const LazyRegionStores = Loadable(
  lazy(() => import("../views/app-components/Regions"))
);

const LazyRegionsAddEdit = Loadable(
  lazy(() => import("../views/app-components/Regions/AddEdit"))
);

const LazyContactFormList = Loadable(
  lazy(() => import("../views/app-components/ContactForm"))
);

const LazyContactFormDetails = Loadable(
  lazy(() => import("../views/app-components/ContactForm/ContactFormDetails"))
);

const LazyFranchiseeContactFormDetails = Loadable(
  lazy(
    () =>
      import("../views/app-components/ContactForm/FranchiseeContactFormDetails")
  )
);

const LazyFranchiseeNews = Loadable(
  lazy(() => import("../views/app-components/FranchiseeNews"))
);

const LazyFranchiseeNewsAddEdit = Loadable(
  lazy(
    () =>
      import("../views/app-components/FranchiseeNews/FranchiseeNewsCreateEdit")
  )
);

const LazyNewsAcknowledgementList = Loadable(
  lazy(
    () => import("../views/app-components/FranchiseeNews/AcknowledgementList")
  )
);

// franchisee post

const LazyFranchiseePost = Loadable(
  lazy(() => import("../views/app-components/FranchiseePosts"))
);

const LazyFranchiseePostAddEdit = Loadable(
  lazy(() => import("../views/app-components/FranchiseePosts/AddEdit"))
);

const LazyMemberNotifications = Loadable(
  lazy(() => import("../views/app-components/MemberNotifications"))
);
const LazyMemberNotificationAddEdit = Loadable(
  lazy(
    () =>
      import(
        "../views/app-components/MemberNotifications/MemberNotificationAddEdit"
      )
  )
);

const LazyClubCignallMemberPrize = Loadable(
  lazy(() => import("../views/app-components/ClubCignallPrizeMailer"))
);

const LazyClubCignallMemberPrizeAdd = Loadable(
  lazy(
    () =>
      import(
        "../views/app-components/ClubCignallPrizeMailer/MailMemberPrizeAdd"
      )
  )
);
const LazyClubCignallMemberEmailPreview = Loadable(
  lazy(
    () =>
      import(
        "../views/app-components/ClubCignallPrizeMailer/components/EmailPreview"
      )
  )
);
const LazyPostAcknowledgementList = Loadable(
  lazy(
    () => import("../views/app-components/FranchiseePosts/AcknowledgementList")
  )
);

//member transfer routing
const LazyClubCignallMemberTransfer = Loadable(
  lazy(() => import("../views/app-components/ClubCignallMemberTransfer"))
);

const LazyClubCignallMemberTransferAddRequest = Loadable(
  lazy(
    () =>
      import("../views/app-components/ClubCignallMemberTransfer/TransferMember")
  )
);

const LazyClubCignallMemberTransferEditRequest = Loadable(
  lazy(
    () =>
      import(
        "../views/app-components/ClubCignallMemberTransfer/EditMemberTransferRequest"
      )
  )
);

//settings page routing
const LazySystemSettings = Loadable(
  lazy(() => import("../views/app-components/Settings/SystemSettings"))
);
const LazyNotificationTemplates = Loadable(
  lazy(() => import("../views/app-components/Settings/NotificationTemplates"))
);

//BDR page routing
const LazyBDRFranchiseePost = Loadable(
  lazy(() => import("../views/app-components/BDR/FranchiseePosts"))
);

const LazyBDRFranchiseePostView = Loadable(
  lazy(
    () =>
      import("../views/app-components/BDR/FranchiseePosts/FranchiseePostView")
  )
);

const LazyBDRFranchiseeNews = Loadable(
  lazy(() => import("../views/app-components/BDR/FranchiseeNews"))
);

const LazyBDRFranchiseeNewsView = Loadable(
  lazy(
    () =>
      import("../views/app-components/BDR/FranchiseeNews/FranchiseeNewsView")
  )
);

const LazyBDRStores = Loadable(
  lazy(() => import("../views/app-components/BDR/Stores"))
);

const LazyBDRStoreView = Loadable(
  lazy(() => import("../views/app-components/BDR/Stores/StoreView"))
);

const PageNotFound = () => {
  return <h1>404 - Page Not Found</h1>;
};

//-----------------------|| MAIN ROUTING ||-----------------------//

function MainRoutes({ admin }) {
  if (!admin) {
    return null;
  }
  if (admin.role === systemRoles.StoreRep) {
    return (
      <MainLayout>
        <Routes>
          {/* Franchisee News */}
          <>
            <Route
              path="/franchisee-news"
              element={<LazyBDRFranchiseeNews />}
            />
            <Route
              path="/franchisee-news/view/:id"
              element={<LazyBDRFranchiseeNewsView />}
            />
            <Route
              path="/franchisee-posts"
              element={<LazyBDRFranchiseePost />}
            />
            <Route
              path="/franchisee-posts/view/:id"
              element={<LazyBDRFranchiseePostView />}
            />
            <Route path="/stores" element={<LazyBDRStores />} />
            <Route path="/stores/view/:id" element={<LazyBDRStoreView />} />
            <Route path="/user-preferences" element={<LazyUserPreferences />} />
            <Route path="/change-password" element={<LazyChangePassword />} />
            <Route
              path="/"
              element={<Navigate to={getInitialRoute(admin.role)} replace />}
            />
            <Route path="*" element={<PageNotFound />} />
          </>
        </Routes>
      </MainLayout>
    );
  }
  return (
    <MainLayout>
      <Routes>
        {/* </AuthGuard> */}

        {/* Club Cignall Member access */}
        {admin && systemModules.HasAccessToClubCignallMembers(admin.modules) ? (
          <>
            <Route
              path="/clubcignallmembers"
              element={<LazyClubCignallMembersHomePage />}
            />
            <Route
              path="/clubcignallmembers/add"
              element={<LazyClubCignallMembersAddPage />}
            />
            <Route
              path="/clubcignallmembers/edit/:memberId"
              element={<LazyClubCignallMembersAddPage />}
            />
          </>
        ) : null}

        {/* Club Cignall Member Transfer */}
        {admin && systemModules.HasAccessToMembersTransfer(admin.modules) ? (
          <>
            <Route
              path="/member-transfer"
              element={<LazyClubCignallMemberTransfer />}
            />
            <Route
              path="/member-transfer/add"
              element={<LazyClubCignallMemberTransferAddRequest />}
            />
            <Route
              path="/member-transfer/edit/:id"
              element={<LazyClubCignallMemberTransferEditRequest />}
            />
          </>
        ) : null}

        {/* Franchisee access */}
        {admin && systemModules.HasAccessToFranchisee(admin.modules) ? (
          <>
            <Route path="/franchisees" element={<LazyFranchisee />} />
            <Route
              path="/franchisees/add"
              element={<LazyFranchiseeAddEdit />}
            />
            <Route
              path="/franchisees/edit/:franchiseeId"
              element={<LazyFranchiseeAddEdit />}
            />
          </>
        ) : null}
        {/* Store access */}
        {admin && systemModules.HasAccessToStores(admin.modules) ? (
          <>
            <Route path="/stores" element={<LazyStores />} />
            <Route path="/stores/add" element={<LazyStoresAddEdit />} />
            <Route
              path="/stores/edit/:storeId"
              element={<LazyStoresAddEdit />}
            />
          </>
        ) : null}
        {/* Region access */}
        {admin && systemModules.HasAccessToRegions(admin.modules) ? (
          <>
            <Route path="/regions" element={<LazyRegions />} />
            <Route path="/regions/add" element={<LazyRegionsAddEdit />} />
            <Route
              path="/regions/edit/:regionId"
              element={<LazyRegionsAddEdit />}
            />
            <Route
              path="/regions/stores/:regionId"
              element={<LazyRegionStores />}
            />
          </>
        ) : null}
        {/* Categories access */}
        {admin && systemModules.HasAccessToCategories(admin.modules) ? (
          <>
            <Route path="/categories" element={<LazyCategories />} />
            <Route path="/categories/add" element={<LazyCategoriesAddEdit />} />
            <Route
              path="/categories/edit/:categoryId"
              element={<LazyCategoriesAddEdit />}
            />
          </>
        ) : null}
        {/* Brands access */}
        {admin && systemModules.HasAccessToBrands(admin.modules) ? (
          <>
            <Route path="/brand/add" element={<LazyBrandAddEdit />} />
          </>
        ) : null}
        {/* Products access */}
        {admin && systemModules.HasAccessToProducts(admin.modules) ? (
          <>
            <Route path="/products" element={<LazyProducts />} />
            <Route path="/products/add" element={<LazyProductAddEdit />} />
            <Route
              path="/products/edit/:productId"
              element={<LazyProductAddEdit />}
            />
          </>
        ) : null}
        {/* Banners access */}
        {admin && systemModules.HasAccessToBanners(admin.modules) ? (
          <>
            <Route path="/banners" element={<LazyBanners />} />
            <Route path="/banners/add" element={<LazyBannersAddEdit />} />
            <Route
              path="/banners/edit/:bannerId"
              element={<LazyBannersAddEdit />}
            />
          </>
        ) : null}
        {/* Franchisee News */}
        {admin && systemModules.HasAccessToFranchiseeNews(admin.modules) ? (
          <>
            <Route path="/franchisee-news" element={<LazyFranchiseeNews />} />
            <Route
              path="/franchisee-news/add"
              element={<LazyFranchiseeNewsAddEdit />}
            />
            <Route
              path="/franchisee-news/edit/:franchiseeNewsId"
              element={<LazyFranchiseeNewsAddEdit />}
            />
            <Route
              path="/news-acknowledgements"
              element={<LazyNewsAcknowledgementList />}
            />
          </>
        ) : null}
        {/* Franchisee Post */}
        {admin && systemModules.HasAccessToFranchiseePosts(admin.modules) ? (
          <>
            <Route path="/franchisee-posts" element={<LazyFranchiseePost />} />
            <Route
              path="/franchisee-posts/add"
              element={<LazyFranchiseePostAddEdit />}
            />
            <Route
              path="/franchisee-posts/edit/:id"
              element={<LazyFranchiseePostAddEdit />}
            />
            <Route
              path="/post-acknowledgements"
              element={<LazyPostAcknowledgementList />}
            />
          </>
        ) : null}
        {/* Member Notifications */}
        {admin &&
        systemModules.HasAccessToMemberNotifications(admin.modules) ? (
          <>
            <Route
              path="/member-notification"
              element={<LazyMemberNotifications />}
            />
            <Route
              path="/member-notification/add"
              element={<LazyMemberNotificationAddEdit />}
            />
            <Route
              path="/member-notification/edit/:notificationId"
              element={<LazyMemberNotificationAddEdit />}
            />
          </>
        ) : null}
        {/*Club Cignall Prizes */}
        {admin && systemModules.HasAccessToClubCignallPrizes(admin.modules) ? (
          <>
            <Route
              path="/club-cignall-prize"
              element={<LazyClubCignallMemberPrize />}
            />
            <Route
              path="/club-cignall-prize/add"
              element={<LazyClubCignallMemberPrizeAdd />}
            />
            <Route
              path="/club-cignall-prize/preview/:clubCignallPrizeId"
              element={<LazyClubCignallMemberEmailPreview />}
            />
          </>
        ) : null}
        {/* Contact Forms */}
        {admin && systemModules.HasAccessToContactForms(admin.modules) ? (
          <>
            <Route path="/contacts" element={<LazyContactFormList />} />
            <Route
              path="/contacts/general/:contactFormId"
              element={<LazyContactFormDetails />}
            />
            <Route
              path="/contacts/franchisee/:franchiseeContactFormId"
              element={<LazyFranchiseeContactFormDetails />}
            />
          </>
        ) : null}
        {/* Admins access */}
        {admin && systemModules.HasAccessToAdmin(admin.modules) ? (
          <>
            <Route path="/admins" element={<LazyAdmin />} />
            <Route path="/admins/add" element={<LazyCreateEditAdmin />} />
            <Route
              path="/admins/edit/:adminId"
              element={<LazyCreateEditAdmin />}
            />
          </>
        ) : null}
        {/* Settings */}
        {admin && systemModules.HasAccessToSettings(admin.modules) ? (
          <>
            <Route path="/system-settings" element={<LazySystemSettings />} />
            <Route
              path="/notification-templates"
              element={<LazyNotificationTemplates />}
            />
          </>
        ) : null}
        <Route path="/user-preferences" element={<LazyUserPreferences />} />
        <Route path="/change-password" element={<LazyChangePassword />} />
        <Route
          path="/login"
          element={<Navigate to={getInitialRoute(admin.role)} replace />}
        />
        <Route
          path="/"
          element={<Navigate to={getInitialRoute(admin.role)} replace />}
        />
        <Route path="*" element={<PageNotFound />} />
        {/* </AuthGuard> */}
      </Routes>
    </MainLayout>
  );
}

export default MainRoutes;
