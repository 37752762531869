import React from "react";
import PropTypes from "prop-types";

// mui-material
import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";

// mui-icons
import { Menu as IconMenu } from "@mui/icons-material";

// project imports
import LogoSection from "layout/MainLayout/LogoSection";
import ProfileSection from "layout/MainLayout/Header/ProfileSection";
//import MobileSection from "layout/MainLayout/Header/MobileSection";

// style constants
const DivGrow = styled("div")(() => ({
  flexGrow: 1,
}));

const Header = ({ drawerToggle }) => {
  return (
    <React.Fragment>
      {/* toggle button */}
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={drawerToggle}
      >
        <IconMenu />
      </IconButton>
      {/* logo */}
      <LogoSection />
      <DivGrow />
      <ProfileSection />

      {/* mobile header */}
      {/* <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <MobileSection />
      </Box> */}
    </React.Fragment>
  );
};

Header.propTypes = {
  drawerToggle: PropTypes.func,
};

export default Header;
