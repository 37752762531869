export const updateNavigationToStore = "NAVIGATED_TO_STORE";
export const updateInitialNavigationToStore =
  "UPDATE_INITIAL_NAVIGATED_TO_STORE";
export const updateSelectedStore = "UPDATE_SELECTED_STORE";
export const updateFranchiseeInitialTab = "UPDATE_FRANCHISEE_INITIAL_TAB";
export const updateFranchiseeTab = "UPDATE_FRANCHISEE_TAB";
const updateFranchiseePostTab = "UPDATE_FRANCHISEE_POST_TAB";

const initialState = {
  navigateToStores: {
    isNavigated: false,
    previousPath: null,
  },
  franchiseeTabs: {
    tab: 0,
  },
  franchiseePostTabs: {
    tab: 0,
  },
  selectedStores: null, // null means initial/clean state, [] means data exists but no stores selected
};

export const actionCreators = {
  setPersistentValues: (previousPath, selectedStores) => (dispatch) => {
    dispatch({
      type: updateNavigationToStore,
      payload: previousPath,
    });
    dispatch({ type: updateSelectedStore, payload: selectedStores });
  },
  resetPersistentValues: () => (dispatch) => {
    dispatch({ type: updateFranchiseeInitialTab });
    dispatch({ type: updateSelectedStore, payload: null });
    dispatch({
      type: updateInitialNavigationToStore,
      payload: initialState.navigateToStores,
    });
  },
  resetSelectedStores: () => (dispatch) => {
    dispatch({ type: updateSelectedStore, payload: null }); // reset to clean/inital state
  },
  updateFranchiseePostTab: (tabIndex) => (dispatch) => {
    dispatch({ type: updateFranchiseePostTab, payload: tabIndex });
  },
};

export const reducer = (state, { type, payload }) => {
  state = state || initialState;
  switch (type) {
    case updateNavigationToStore:
      return {
        ...state,
        navigateToStores: {
          isNavigated: true,
          previousPath: payload,
        },
      };
    case updateInitialNavigationToStore:
      return { ...state, navigateToStores: initialState.navigateToStores };
    case updateSelectedStore:
      return { ...state, selectedStores: payload };
    case updateFranchiseeInitialTab:
      return { ...state, franchiseeTabs: initialState.franchiseeTabs };
    case updateFranchiseeTab:
      return { ...state, franchiseeTabs: payload };
    case updateFranchiseePostTab:
      return {
        ...state,
        franchiseePostTabs: {
          ...state.franchiseePostTabs,
          tab: payload,
        },
      };
    default:
      return state;
  }
};
