import { fetchGet, fetchPost, fetchPut } from "utils/fetch-webapi";
import { SNACKBAR_OPEN } from "./navigationActions";

const requestConfigChoices = "REQUEST_CONFIG_CHOICES";
const responseConfigChoices = "RESPONSE_CONFIG_CHOICES";
const requestProducts = "REQUEST_PRODUCTS";
const responseProducts = "RESPONSE_PRODUCTS";
const requestProductsBrands = "REQUEST_PRODUCT_BRANDS";
const responseProductsBrands = "RESPONSE_PRODUCT_BRANDS";
const requestProductCategories = "REQUEST_PRODUCT_CATEGORIES";
const responseProductCategories = "RESPONSE_PRODUCT_CATEGORIES";
const requestProductSearchBrand = "REQUEST_PRODUCT_SEARCH_BRAND";
const responseProductSearchBrand = "RESPONSE_PRODUCT_SEARCH_BRAND";
export const requestProductById = "REQUEST_PRODUCT_BY_ID";
export const responseProductById = "RESPONSE_PRODUCT_BY_ID";
export const retainProductsFieldValues = "RETAIN_PRODUCT_FIELD_VALUE";

const requestReInitialize = "REQUEST_REINITIALIZE";
const updateProductSearchTerm = "UPDATE_PRODUCT_SEARCH_TERM";

export const actionCreators = {
  getAllProducts: (searchTerm, pageNo, rowsPerPage) => async (dispatch) => {
    try {
      dispatch({ type: requestProducts });
      const [, response] = await fetchPost("/products", {
        searchTerm,
        pageNo,
        rowsPerPage,
      });
      dispatch({ type: responseProducts, payload: response });
    } catch (e) {
      console.log("Error while trying to request products " + e);
    }
  },

  getProductById: (productId) => async (dispatch) => {
    try {
      dispatch({ type: requestProductById });
      const [, response] = await fetchGet(`/product/${productId}`);
      dispatch({ type: responseProductById, payload: response });
    } catch (e) {
      console.log("Error while trying to request product" + e);
    }
  },

  getCategories: () => async (dispatch) => {
    try {
      dispatch({ type: requestProductCategories });
      const [, response] = await fetchGet("/categories");
      dispatch({ type: responseProductCategories, payload: response });
    } catch (e) {
      console.log("Error while trying to request category " + e);
    }
  },

  // getAllBrands: () => async (dispatch) => {
  //   try {
  //     dispatch({ type: requestProductsBrands });
  //     const [, response] = await fetchGet("/brands");
  //     dispatch({ type: responseProductsBrands, payload: response });
  //   } catch (e) {
  //     console.log("Error while trying to get all brands " + e);
  //   }
  // },

  getProductsLookupChoices: () => async (dispatch) => {
    try {
      dispatch({ type: requestConfigChoices });
      const [, response] = await fetchGet("/products-configs");
      dispatch({ type: responseConfigChoices, payload: response });
    } catch (e) {
      console.log("Error while trying to get all brands " + e);
    }
  },

  searchBrand: (searchTerm) => async (dispatch) => {
    try {
      dispatch({ type: requestProductSearchBrand });
      const [, response] = await fetchPost("/search/brands", {
        searchTerm,
      });
      dispatch({ type: responseProductSearchBrand, payload: response });
    } catch (e) {
      console.log("Error while searching brand ", e);
    }
  },

  updateBrandsStatus: (values, searchTerm) => async (dispatch) => {
    try {
      const [, response] = await fetchPut("/brand", {
        ...values,
        status: !values.status,
      });
      if (response && response.errorNo === 0)
        dispatch(actionCreators.searchBrand(searchTerm));
    } catch (e) {
      console.log("Error while trying to update brand " + e);
    }
  },

  updateBrand:
    (values, brandName, setSubmitting, resetForm) => async (dispatch) => {
      try {
        setSubmitting(true);
        const [, response] = await fetchPut("/brand", {
          ...values,
          brandName: brandName,
        });
        if (response && response.errorNo === 0) {
          dispatch(actionCreators.searchBrand());
          resetForm();
        }
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: response.message,
          variant: "alert",
          alertSeverity: `${response.errorNo > 0 ? "error" : "success"}`,
        });
        setSubmitting(false);
      } catch (e) {
        setSubmitting(false);
        console.log("Error while trying to update brand " + e);
      }
    },

  addBrand: (values, setSubmitting, resetForm) => async (dispatch) => {
    try {
      setSubmitting(true);
      const [, response] = await fetchPost("/brand", {
        ...values,
      });
      if (response && response.errorNo === 0) {
        dispatch(actionCreators.searchBrand());
        resetForm();
      }

      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: response.message,
        variant: "alert",
        alertSeverity: `${response.errorNo > 0 ? "error" : "success"}`,
      });
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
      console.log("Error while adding Brand ", e);
    }
  },

  addProduct: (values, setSubmitting, resetForm) => async (dispatch) => {
    try {
      setSubmitting(true);
      const [, response] = await fetchPost("/product", {
        ...values,
      });
      if (response && response.errorNo === 0) {
        dispatch({
          type: retainProductsFieldValues,
          payload: { ...initialState.productFields },
        });
        resetForm({ values });
      }

      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: response.message,
        variant: "alert",
        alertSeverity: `${response.errorNo > 0 ? "error" : "success"}`,
      });
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
      console.log("Error while adding product ", e);
    }
  },

  updateProduct:
    (values, setSubmitting, resetForm, navigateBack) => async (dispatch) => {
      try {
        setSubmitting(true);
        const [, response] = await fetchPut("/product", {
          ...values,
        });

        if (response && response.errorNo === 0) {
          resetForm({ values });
          navigateBack();
        }

        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: response.message,
          variant: "alert",
          alertSeverity: `${response.errorNo > 0 ? "error" : "success"}`,
        });
        setSubmitting(false);
      } catch (e) {
        setSubmitting(false);
        console.log("Error while adding product ", e);
      }
    },

  clearProductList: () => async (dispatch) => {
    dispatch({ type: responseProducts, payload: [] });
  },

  setProductSearchTerm: (term) => (dispatch) => {
    dispatch({ type: updateProductSearchTerm, payload: term });
  },
};

const initialState = {
  requestProduct: false,
  productsList: null,
  gettingCategories: false,
  categories: null,
  gettingBrands: false,
  brands: null,
  gettingChoices: false,
  choices: null,
  gettingProductById: false,
  product: null,
  productFields: {
    productName: "",
    brandId: "",
    status: "Enabled",
    categoryId: "select",
    unitValue: "",
    unitTypeId: "select",
    packageUnitValue: "",
    packageTypeId: "select",
    manufacturerId: "select",
    hoProductNumber: "",
    ccPreferenceAvailable: "Yes",
    gtin: "",
    brand: "",
    brandTextName: "",
  },
  productSearchTerm: "",
};

export const reducer = (state, { type, payload }) => {
  state = state || initialState;
  switch (type) {
    case retainProductsFieldValues:
      return { ...state, productFields: payload };
    case requestReInitialize:
      return { ...state, gettingProductById: false, product: null };
    case requestProducts:
      return { ...state, requestProduct: true };
    case responseProducts:
      return { ...state, requestProduct: false, productsList: payload };
    case requestProductCategories:
      return { ...state, gettingCategories: true };
    case responseProductCategories:
      return { ...state, gettingCategories: false, categories: payload };
    case requestProductsBrands:
      return { ...state, gettingBrands: true };
    case responseProductsBrands:
      return { ...state, gettingBrands: false, brands: payload };
    case requestProductSearchBrand:
      return { ...state, gettingBrands: true };
    case responseProductSearchBrand:
      return { ...state, gettingBrands: false, brands: payload };
    case requestConfigChoices:
      return { ...state, gettingChoices: true };
    case responseConfigChoices:
      return { ...state, gettingChoices: false, choices: payload };
    case requestProductById:
      return { ...state, gettingProductById: true };
    case responseProductById:
      return { ...state, gettingProductById: false, product: payload };
    case updateProductSearchTerm:
      return { ...state, productSearchTerm: payload };
    default:
      return state;
  }
};
