import { fetchGet, fetchPost, fetchPut } from "utils/fetch-webapi";
import { SNACKBAR_OPEN } from "store/navigationActions";

const types = {
  getAllRegions: "GET_ALL_REGIONS",
  getAllRegionsResponse: "GET_ALL_REGIONS_RESPONSE",

  getRegionById: "GET_REGION_BY_ID",
  getRegionByIdResponse: "GET_REGION_BY_ID_RESPONSE",

  updateRegionById: "UPDATE_REGION_BY_ID",

  saveDraft: "SAVE_DRAFT",

  updateSelectedRegionStores: "UPDATE_SELECTED_REGION_STORES",
  exitEditRegionScreen: "EXIT_EDITING_REGION_SCREEN",
  updateRegionSearchTerm: "UPDATE_REGION_SEARCH_TERM",
};

export const actionCreators = {
  getAllRegions:
    (searchTerm, pageNo = 1, rowsPerPage, state, status) =>
    async (dispatch) => {
      try {
        dispatch({ type: types.getAllRegions });

        // query string
        var queryStr = "?";
        if (searchTerm) queryStr += `searchTerm=${searchTerm}&`;
        if (pageNo) queryStr += `pageNo=${pageNo}&`;
        if (rowsPerPage) queryStr += `rowsPerPage=${rowsPerPage}&`;
        if (state) queryStr += `state=${state}`;
        if (status !== undefined) queryStr += `status=${status}&`;
        if (queryStr === "?") queryStr = "";

        const [, response] = await fetchGet("/regions" + queryStr);
        dispatch({ type: types.getAllRegionsResponse, payload: response });
      } catch (e) {
        console.log("Error while trying to get regions " + e);
      }
    },

  getRegionById: (regionId) => async (dispatch) => {
    try {
      dispatch({ type: types.getRegionById });

      const [, regionResponse] = await fetchGet("/regions/" + regionId);
      const [, storesResponse] = await fetchGet("/regions/stores/" + regionId);
      dispatch({
        type: types.getRegionByIdResponse,
        payload: {
          region: regionResponse,
          regionStores: storesResponse,
        },
      });
    } catch (e) {
      console.log("Error while trying to get region " + e);
    }
  },

  createRegion:
    (values, setSubmitting, resetForm, setStoreListChanged, navigate) =>
    async (dispatch) => {
      try {
        setSubmitting(true);
        const [, response] = await fetchPost("/region", values);
        if (response && response.errorNo === 0) {
          resetForm({ values });
          setStoreListChanged(false);
          navigate();
        }
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: response.message,
          variant: "alert",
          alertSeverity: `${response.errorNo > 0 ? "error" : "success"}`,
        });
      } catch (e) {
        console.log("Error while creating region", e);
      } finally {
        setSubmitting(false);
      }
    },

  updateRegion:
    (values, setSubmitting, resetForm, setStoreListChanged, navigate) =>
    async (dispatch) => {
      try {
        setSubmitting(true);
        const [, response] = await fetchPut("/region", values);
        if (response && response.errorNo === 0) {
          resetForm({ values });
          setStoreListChanged(false);
          navigate();
        }
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: response.message,
          variant: "alert",
          alertSeverity: `${response.errorNo > 0 ? "error" : "success"}`,
        });
      } catch (e) {
        console.log("Error while updating region", e);
      } finally {
        setSubmitting(false);
      }
    },

  setSelectedRegionStores: (stores) => (dispatch) => {
    dispatch({ type: types.updateSelectedRegionStores, payload: stores });
  },

  saveDraft: (values) => (dispatch) => {
    dispatch({ type: types.saveDraft, payload: values });
  },

  exitEditRegionScreen: () => (dispatch) => {
    dispatch({ type: types.exitEditRegionScreen });
  },

  setRegionSearchTerm: (values) => (dispatch) => {
    dispatch({ type: types.updateRegionSearchTerm, payload: values });
  },

  clearRegionList: () => async (dispatch) => {
    dispatch({ type: types.getAllRegionsResponse, payload: [] });
  },
};

const initialState = {
  loading: false,
  data: null,
  editingRegion: null,
  regionStores: [],
  regionSearchTerm: "",
};

export const reducer = (state, { type, payload }) => {
  state = state || initialState;
  switch (type) {
    case types.getAllRegions:
      return { ...state, loading: true, data: null };
    case types.getAllRegionsResponse:
      return { ...state, loading: false, data: payload };
    case types.getRegionById:
      return { ...state, loading: true, editingRegion: null };
    case types.getRegionByIdResponse:
      return {
        ...state,
        loading: false,
        editingRegion: payload.region,
        regionStores: payload.regionStores,
      };
    case types.updateSelectedRegionStores:
      return { ...state, regionStores: payload };
    case types.saveDraft:
      return {
        ...state,
        editingRegion: {
          ...state.editingRegion,
          regionName: payload.regionName,
          status: payload.status === "Enable",
        },
      };
    case types.exitEditRegionScreen:
      return { ...state, editingRegion: null, regionStores: [] };
    case types.updateRegionSearchTerm:
      return { ...state, regionSearchTerm: payload };
    default:
      return state;
  }
};
