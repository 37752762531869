import { fetchGet, fetchPost, fetchPut } from "utils/fetch-webapi";
import { SNACKBAR_OPEN } from "store/navigationActions";
import { isValidInt } from "utils/input-sanitize";

const requestAllFranchiseeList = "REQUEST_ALL_FRANCHISEE_LIST";
const responseAllFranchiseeList = "RESPONSE_ALL_FRANCHISEE_LIST";
const requestFranchiseeById = "REQUEST_FRANCHISEE_BY_ID";
const responseFranchiseeById = "RESPONSE_FRANCHISEE_BY_ID";
const requestFranchiseeLogin = "REQUEST_FRANCHISEE_LOGIN";
const responseFranchiseeLogin = "RESPONSE_FRANCHISEE_LOGIN";
const requestFranchiseeFormSubmit = "REQUEST_FRANCHISEE_FORM_SUBMIT";
const responseFranchiseeFormSubmit = "RESPONSE_FRANCHISEE_FORM_SUBMIT";

const updatefranchiseeStoresType = "UPDATE_FRANCHISEE_STORES_TYPE";
const exitFranchiseeEditScreenType = "EXIT_FRANCHISEE_EDIT_SCREEN_TYPE";

export const updateFranchiseeFieldsValue = "UPDATE_FRANCHISEE_FIELDS_VALUE";
const updateFranchiseeSearchTerm = "UPDATE_FRANCHISEE_SEARCH_TERM";
const updatefranchiseeNotFound = "UPDATE_FRANCHISEE_NOT_FOUND";

export const actionCreators = {
  getAllFranchiseeSearch:
    (searchTerm, pageNo, rowsPerPage) => async (dispatch) => {
      try {
        dispatch({ type: requestAllFranchiseeList });
        const [, response] = await fetchPost("/franchisees", {
          searchTerm,
          pageNo,
          rowsPerPage,
        });
        dispatch({ type: responseAllFranchiseeList, payload: response });
      } catch (e) {
        console.log("Error while trying to request franchisee list " + e);
      }
    },

  getFranchiseeById: (franchiseeId) => async (dispatch) => {
    try {
      if (isValidInt(franchiseeId)) {
        dispatch({ type: requestFranchiseeById, payload: true });
        const [, response] = await fetchGet(`/franchisee/${franchiseeId}`);
        dispatch({ type: responseFranchiseeById, payload: response });
      } else {
        dispatch({ type: updatefranchiseeNotFound, payload: true });
      }
    } catch (e) {
      dispatch({ type: requestFranchiseeById, payload: false });
      console.log("Error while trying to request franchisee by id " + e);
    }
  },

  getFranchiseeNextLoginId: () => async (dispatch) => {
    try {
      dispatch({ type: requestFranchiseeLogin });
      const [, response] = await fetchGet("/franchisee-login");
      dispatch({ type: responseFranchiseeLogin, payload: response });
    } catch (e) {
      console.log("Error while trying to request next franchisee id " + e);
    }
  },

  createFranchisee: (values, setSubmitting, resetForm) => async (dispatch) => {
    try {
      const [, response] = await fetchPost("/franchisee", {
        ...values,
        mobileNumber: values.mobileNumber.replaceAll("-", ""),
        status: values.status === "Enable",
      });
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: response.message,
        variant: "alert",
        alertSeverity: `${
          response && response.errorNo && response.errorNo > 0
            ? "error"
            : "success"
        }`,
      });
      if (response && response.errorNo === 0) {
        dispatch(actionCreators.getFranchiseeLogin());
        dispatch({ type: exitFranchiseeEditScreenType });
        resetForm();
      }
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
      console.log("Error while trying to request franchisee list " + e);
    }
  },

  updateFranchisee:
    (values, setSubmitting, navigateToParentPage) => async (dispatch) => {
      try {
        dispatch({ type: requestFranchiseeFormSubmit });
        const [, response] = await fetchPut("/franchisee", {
          ...values,
          mobileNumber: values.mobileNumber.replaceAll("-", ""),
          status: values.status === "Enable",
        });
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: response.message,
          variant: "alert",
          alertSeverity: `${
            response && response.errorNo && response.errorNo > 0
              ? "error"
              : "success"
          }`,
        });

        if (response && response.errorNo === 0) {
          navigateToParentPage();
        }
        setSubmitting(false);
        dispatch({ type: responseFranchiseeFormSubmit });
      } catch (e) {
        setSubmitting(false);
        console.log("Error while trying to request franchisee list " + e);
      }
    },

  updateFranchiseePassword: (franchiseeId, password) => async (dispatch) => {
    try {
      const [, response] = await fetchPost("/franchisee/password-reset", {
        franchiseeId,
        password,
      });

      dispatch({
        type: SNACKBAR_OPEN,
        anchorOrigin: { vertical: "top", horizontal: "right" },
        transition: "SlideLeft",
        open: true,
        message: response.message,
        variant: "alert",
        alertSeverity: `${
          response && response.errorNo && response.errorNo > 0
            ? "error"
            : "success"
        }`,
        close: true,
      });
    } catch (e) {
      console.log("Error while trying to update password");
    }
  },

  updatefranchiseeStores: (stores) => (dispatch) => {
    dispatch({ type: updatefranchiseeStoresType, payload: stores });
  },

  updateFranchiseeFields: (values) => (dispatch) => {
    dispatch({ type: updateFranchiseeFieldsValue, payload: values });
  },

  exitFranchiseeEditScreen: () => (dispatch) => {
    dispatch({ type: exitFranchiseeEditScreenType });
  },
  clearFranchiseeList: () => async (dispatch) => {
    dispatch({ type: responseAllFranchiseeList, payload: [] });
  },
  setFranchiseeSearchTerm: (term) => async (dispatch) => {
    dispatch({ type: updateFranchiseeSearchTerm, payload: term });
  },
};

const initialState = {
  gettingFranchisee: false,
  franchiseeList: null,
  requestFranchiseById: false,
  franchiseeNotFound: false,
  franchiseeDetails: null,
  franchiseeStores: [],
  franchiseeFields: {
    franchiseeLogin: "",
    password: "",
    status: "Enable",
    firstName: "",
    surName: "",
    email: "",
    mobileNumber: "",
    dateOfBirth: "",
  },
  loginId: null,
  gettingFranchiseeLogin: false,
  franchiseeSearchTerm: "",
};

export const reducer = (state, { type, payload }) => {
  state = state || initialState;
  switch (type) {
    case requestFranchiseeLogin:
      return { ...state, gettingFranchiseeLogin: true };
    case responseFranchiseeLogin:
      return {
        ...state,
        gettingFranchiseeLogin: false,
        loginId: payload,
      };
    case requestAllFranchiseeList:
      return { ...state, gettingFranchisee: true };
    case responseAllFranchiseeList:
      return { ...state, gettingFranchisee: false, franchiseeList: payload };
    case requestFranchiseeById:
      return { ...state, requestFranchiseById: payload };
    case responseFranchiseeById:
      return {
        ...state,
        requestFranchiseById: false,
        franchiseeNotFound:
          payload === null || payload.franchisee === undefined,
        franchiseeDetails: payload,
        franchiseeStores: payload.franchiseeStores.map(
          (franchisee) => franchisee.store
        ),
      };
    case updateFranchiseeFieldsValue:
      return {
        ...state,
        franchiseeDetails: {
          ...state.franchiseeDetails,
          franchisee: payload,
        },
      };
    case requestFranchiseeFormSubmit:
      return { ...state, formSubmitting: true };
    case responseFranchiseeFormSubmit:
      return { ...state, formSubmitting: false };
    case updatefranchiseeStoresType:
      return { ...state, franchiseeStores: payload };
    case exitFranchiseeEditScreenType:
      return {
        ...state,
        franchiseeDetails: null,
        franchiseeNotFound: false,
        franchiseeStores: [],
        franchiseeFields: initialState.franchiseeFields,
      };
    case updateFranchiseeSearchTerm:
      return { ...state, franchiseeSearchTerm: payload };
    case updatefranchiseeNotFound:
      return { ...state, franchiseeNotFound: payload };
    default:
      return state;
  }
};
