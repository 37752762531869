export class systemModules {
  static get Admin() {
    return "12";
  }
  static get Banners() {
    return "6";
  }
  static get Brands() {
    return "5";
  }
  static get Categories() {
    return "4";
  }
  static get ClubCignallMembers() {
    return "0";
  }
  static get ClubCignallPrize() {
    return "10";
  }
  static get ContactForms() {
    return "11";
  }
  static get Franchisee() {
    return "1";
  }
  static get FranchiseeNews() {
    return "8";
  }
  static get FranchiseePosts() {
    return "7";
  }
  static get MemberNotifications() {
    return "9";
  }
  static get MembersTransfer() {
    return "14";
  }
  static get Regions() {
    return "3";
  }
  static get Settings() {
    return "13";
  }
  static get Stores() {
    return "2";
  }

  static get Products() {
    return "15";
  }

  static HasAccessToAdmin(modules) {
    return modules?.some((m) => m === systemModules.Admin);
  }

  static HasAccessToBanners(modules) {
    return modules?.some((m) => m === systemModules.Banners);
  }

  static HasAccessToBrands(modules) {
    return modules?.some((m) => m === systemModules.Brands);
  }

  static HasAccessToProducts(modules) {
    return modules?.some((m) => m === systemModules.Products);
  }

  static HasAccessToCategories(modules) {
    return modules?.some((m) => m === systemModules.Categories);
  }

  static HasAccessToClubCignallMembers(modules) {
    return modules?.some((m) => m === systemModules.ClubCignallMembers);
  }

  static HasAccessToClubCignallPrizes(modules) {
    return modules?.some((m) => m === systemModules.ClubCignallPrize);
  }

  static HasAccessToContactForms(modules) {
    return modules?.some((m) => m === systemModules.ContactForms);
  }

  static HasAccessToFranchisee(modules) {
    return modules?.some((m) => m === systemModules.Franchisee);
  }

  static HasAccessToFranchiseeNews(modules) {
    return modules?.some((m) => m === systemModules.FranchiseeNews);
  }

  static HasAccessToFranchiseePosts(modules) {
    return modules?.some((m) => m === systemModules.FranchiseePosts);
  }

  static HasAccessToMemberNotifications(modules) {
    return modules?.some((m) => m === systemModules.MemberNotifications);
  }

  static HasAccessToMembersTransfer(modules) {
    return modules?.some((m) => m === systemModules.MembersTransfer);
  }

  static HasAccessToRegions(modules) {
    return modules?.some((m) => m === systemModules.Regions);
  }

  static HasAccessToSettings(modules) {
    return modules?.some((m) => m === systemModules.Settings);
  }

  static HasAccessToStores(modules) {
    return modules?.some((m) => m === systemModules.Stores);
  }
}
