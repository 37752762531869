import { fetchGet, fetchPost, fetchPut } from "utils/fetch-webapi";
import { SNACKBAR_OPEN } from "./navigationActions";

const requestMemTransferStatusList = "REQUEST_MEMBERS_TRANSFER_STATUS_LIST";
const responseMemTransferStatusList = "RESPONSE_MEMBERS_TRANSFER_STATUS_LIST";

const requestMembersTransferList = "REQUEST_MEMBERS_TRANSFER_LIST";
const responseMembersTransferList = "RESPONSE_MEMBERS_TRANSFER_LIST";

const requestMembersTransferById = "REQUEST_MEMBERS_TRANSFER_BY_ID";
const responseMembersTransferById = "RESPONSE_MEMBERS_TRANSFER_BY_ID";

const updateMembersTransferRequestSearchTerm =
  "UPDATE_MEMBERS_TRANSFER_REQUEST_SEARCH_TERM";

export const actionCreators = {
  getMemberTransferStatusList: () => async (dispatch) => {
    try {
      dispatch({ type: requestMemTransferStatusList });
      const [, response] = await fetchGet("/membersTransferStatus");
      dispatch({ type: responseMemTransferStatusList, payload: response });
    } catch (e) {
      console.log("Error while loding the member transfer status list " + e);
    }
  },

  getMembersTransferRequestListByStatusId:
    (statusId, pageNo, rowsPerPage) => async (dispatch) => {
      try {
        dispatch({ type: requestMembersTransferList });
        const [, response] = await fetchPost(
          "/memberTransferRequestListByStatusId",
          {
            statusId,
            pageNo,
            rowsPerPage,
          }
        );
        dispatch({ type: responseMembersTransferList, payload: response });
      } catch (e) {
        console.log("Error while getting list " + e);
      }
    },

  getMembersTransferRequestById: (id) => async (dispatch) => {
    try {
      dispatch({ type: requestMembersTransferById });
      const [, response] = await fetchGet(`/membersTransferRequest/${id}`);
      dispatch({ type: responseMembersTransferById, payload: response });
    } catch (e) {
      console.log("Error while getting the transfer request by id. " + e);
    }
  },

  transferClubCignallMembers: (fromStoreId, toStoreId) => async (dispatch) => {
    try {
      const [,] = await fetchPut("/membersTransferRequest", {
        fromStoreId,
        toStoreId,
      });
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: response.message,
        variant: "alert",
        alertSeverity: `${
          response && response.errorNo && response.errorNo > 0
            ? "error"
            : "success"
        }`,
      });
    } catch (e) {
      console.log("Error while transferring club cignall members.", e);
    }
  },

  updateMemberTransferRequestStatus:
    (values, setSubmitting, navigateToParentPage) => async (dispatch) => {
      try {
        const [, response] = await fetchPut(
          "/updateMembersTransferRequestStatus",
          { ...values }
        );

        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: response.message,
          variant: "alert",
          alertSeverity: `${
            response && response.errorNo && response.errorNo > 0
              ? "error"
              : "success"
          }`,
        });
        setSubmitting(false);

        if (response && response.errorNo === 0) {
          navigateToParentPage();
        }
      } catch (e) {
        console.log("Error while updating members transfer request data. ", e);
        setSubmitting(false);
      }
    },

  setMembersTransferRequestSearchTerm: (statusId) => async (dispatch) => {
    dispatch({
      type: updateMembersTransferRequestSearchTerm,
      payload: statusId,
    });
  },
};

const initialState = {
  loadingMemberTransferStatus: false,
  memberTransferStatusList: null,
  gettingMemberTransferRequestList: false,
  memberTransferRequestList: null,
  transferStatusId: 1,
  gettingMemberTransferRequestById: false,
  membersTransferRequestById: null,
};

export const reducer = (state, { type, payload }) => {
  state = state || initialState;
  switch (type) {
    case requestMemTransferStatusList:
      return { ...state, loadingMemberTransferStatus: true };
    case responseMemTransferStatusList:
      return { ...state, memberTransferStatusList: payload };
    case requestMembersTransferList:
      return { ...state, gettingMemberTransferRequestList: true };
    case responseMembersTransferList:
      return {
        ...state,
        gettingMemberTransferRequestList: false,
        memberTransferRequestList: payload,
      };
    case updateMembersTransferRequestSearchTerm:
      return { ...state, transferStatusId: payload };
    case requestMembersTransferById:
      return { ...state, gettingMemberTransferRequestById: true };
    case responseMembersTransferById:
      return {
        ...state,
        gettingMemberTransferRequestById: false,
        membersTransferRequestById: payload,
      };
    default:
      return state;
  }
};
