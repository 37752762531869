import {
  fetchGet,
  fetchGetHtmlTemplate,
  fetchPost,
  fetchPut,
} from "utils/fetch-webapi";
import { SNACKBAR_OPEN } from "./navigationActions";

const requestEmailTypeAvailableVariable = "REQUEST_EMAIL_TYPE_AVAILABLE_VARS";
const responseEmailTypeAvailableVariable = "RESPONSE_EMAIL_TYPE_AVAILABLE_VARS";
const requestEmailTypePrizes = "REQUEST_EMAIL_TYPE_PRIZES";
export const responseEmailTypePrizes = "RESPONSE_EMAIL_TYPE_PRIZES";
const requestClubCignallPrize = "REQUEST_CLUB_CIGNALL_PRIZE";
const responseClubCignallPrize = "RESPONSE_CLUB_CIGNALL_PRIZE";
const requestEmailPreviewTemplate = "REQUEST_EMAIL_PREVIEW_TEMPLATE";
const responseEmailPreviewTemplate = "RESPONSE_EMAIL_PREVIEW_TEMPLATE";
const requestMemberValidation = "REQUEST_MEMBER_VALIDATION";
const responseMemberValidation = "RESPONSE_MEMBER_VALIDATION";

export const updateSelectedTabIndex = "UPDATE_SELECTED_TAB_INDEX";

export const actionCreators = {
  getAvailableEmailVariables: (emailType) => async (dispatch) => {
    try {
      dispatch({ type: requestEmailTypeAvailableVariable });
      const [, response] = await fetchGet(
        `/email-available-variables/${emailType}`
      );
      dispatch({ type: responseEmailTypeAvailableVariable, payload: response });
    } catch (e) {
      console.log("Error while trying to request email variables " + e);
    }
  },

  getEmailPrizeList: (emailType) => async (dispatch) => {
    try {
      dispatch({ type: requestEmailTypePrizes });
      const [, response] = await fetchGet(`/club-cignall-prize/${emailType}`);
      dispatch({ type: responseEmailTypePrizes, payload: response });
    } catch (e) {
      console.log("Error while trying to request email variables " + e);
    }
  },

  sendEmailToMembers:
    (emailVariablesValue, clubCignallPrizeType, setSubmitting, resetForm) =>
    async (dispatch) => {
      try {
        const [, response] = await fetchPost("/send-prize-mail", {
          clubCignallPrizeType,
          emailVariablesValue,
        });
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: response && response.message,
          variant: "alert",
          alertSeverity: `${response.errorNo > 0 ? "error" : "success"}`,
        });
        setSubmitting(false);
        resetForm();
      } catch (e) {
        setSubmitting(false);
        console.log("Error while trying to send email to members " + e);
      }
    },

  getEmailTemplates: (clubCignallPrizeId) => async (dispatch) => {
    try {
      dispatch({ type: requestEmailPreviewTemplate });
      const [, response] = await fetchGetHtmlTemplate(
        `/preview-email-template/${clubCignallPrizeId}`
      );
      dispatch({ type: responseEmailPreviewTemplate, payload: response });
    } catch (e) {
      console.log("Error while trying to request email template " + e);
    }
  },

  getClubCignallPrizeById: (clubCignallPrizeId) => async (dispatch) => {
    try {
      dispatch({ type: requestClubCignallPrize });
      const [, response] = await fetchGet(`/prize-email/${clubCignallPrizeId}`);
      dispatch({ type: responseClubCignallPrize, payload: response });
    } catch (e) {
      console.log("Error while trying to request prize " + e);
    }
  },

  approveEmailTemplate:
    (clubCignallPrizeId, navigateToMainPage) => async (dispatch) => {
      try {
        const [, response] = await fetchPut(
          "/approve-clubcignall-prize-email",
          {
            clubCignallPrizeId,
          }
        );
        if (response.errorNo === 0) {
          navigateToMainPage();
        }
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: response && response.message,
          variant: "alert",
          alertSeverity: `${response.errorNo > 0 ? "error" : "success"}`,
        });
      } catch (e) {
        console.log("Error while trying to request email approval " + e);
      }
    },

  validateEmail: (numbers) => async (dispatch) => {
    const memberNos = numbers.split(",");
    dispatch({ type: requestMemberValidation });
    const [, response] = await fetchPost("/validate-member-email", {
      memberNos,
    });

    if (
      response.members ||
      (response.validatedMembers && response.validatedMembers.length > 0)
    ) {
      if (response.members && response.members.length > 0) {
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message:
            response.members.length > 1
              ? `Invalid member numbers ${response.members.map(
                  (m) => m.memberNo
                )}`
              : `Invalid member number ${response.members.map(
                  (m) => m.memberNo
                )}`,
          variant: "alert",
          alertSeverity: "error",
        });
      }
      if (response.validatedMembers && response.validatedMembers.length > 0) {
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: `Email address is empty for ${response.validatedMembers.map(
            (m) => m.memberNo
          )}`,
          variant: "alert",
          alertSeverity: "error",
        });
      }
    } else {
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: "Successfully validated",
        variant: "alert",
        alertSeverity: "success",
      });
    }
    dispatch({ type: responseMemberValidation, payload: response });
  },
};

const initialState = {
  gettingAvailableVars: false,
  variables: null,
  tabIndex: 0,
  loading: false,
  prizes: null,
  gettingClubCignallPrize: false,
  prizeDetail: null,
  gettingEmailPreviewTemplate: false,
  template: null,
  isValidating: false,
  validatedMembers: null,
};

export const reducer = (state, { type, payload }) => {
  state = state || initialState;
  switch (type) {
    case requestEmailTypeAvailableVariable:
      return { ...state, gettingAvailableVars: true, variables: null };
    case responseEmailTypeAvailableVariable:
      return { ...state, gettingAvailableVars: false, variables: payload };
    case requestEmailTypePrizes:
      return { ...state, loading: true };
    case responseEmailTypePrizes:
      return { ...state, loading: false, prizes: payload };
    case requestClubCignallPrize:
      return { ...state, gettingClubCignallPrize: true };
    case responseClubCignallPrize:
      return { ...state, gettingClubCignallPrize: false, prizeDetail: payload };
    case updateSelectedTabIndex:
      return { ...state, tabIndex: payload };
    case requestEmailPreviewTemplate:
      return { ...state, gettingEmailPreviewTemplate: true };
    case responseEmailPreviewTemplate:
      return {
        ...state,
        gettingEmailPreviewTemplate: false,
        template: payload,
      };
    case requestMemberValidation:
      return { ...state, isValidating: true };
    case responseMemberValidation:
      return { ...state, isValidating: false, validatedMembers: payload };
    default:
      return state;
  }
};
