import React from "react";

// project imports
import CignallLogo from "ui-component/CignallLogo";

//-----------------------|| MAIN LOGO ||-----------------------//

const LogoSection = () => {
  return (
    <>
      {/*<Logo />*/}
      <CignallLogo />
    </>
  );
};

export default LogoSection;
