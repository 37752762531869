/* eslint-disable react/display-name */
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";

// project imports
import {
  MENU_OPEN,
  SET_MENU,
  convertActionToMenuOpen,
} from "store/navigationActions";

// assets
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { cignallThemeColor } from "themes/constants";

const PREFIX = "NavItem";

const classes = {
  listIcon: `${PREFIX}-listIcon`,
  listCustomIconSub: `${PREFIX}-listCustomIconSub`,
  listCustomIconSubActive: `${PREFIX}-listCustomIconSubActive`,
  listItem: `${PREFIX}-listItem`,
  listItemNoBack: `${PREFIX}-listItemNoBack`,
  subMenuCaption: `${PREFIX}-subMenuCaption`,
};

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  [`& .${classes.listIcon}`]: {
    minWidth: "18px",
    marginTop: "auto",
    marginBottom: "auto",
  },

  [`& .${classes.listCustomIconSub}`]: {
    width: "6px",
    height: "6px",
  },

  [`& .${classes.listCustomIconSubActive}`]: {
    width: "8px",
    height: "8px",
  },

  [`& .${classes.listItem}`]: {
    marginBottom: "5px",
    alignItems: "center",
  },

  [`& .${classes.listItemNoBack}`]: {
    marginBottom: "5px",
    backgroundColor: "transparent !important",
    paddingTop: "8px",
    paddingBottom: "8px",
    alignItems: "flex-start",
  },

  [`& .${classes.subMenuCaption}`]: {
    ...theme.typography.subMenuCaption,
  },
}));

//-----------------------|| SIDEBAR MENU LIST ITEMS ||-----------------------//

const NavItem = ({ item, level }) => {
  const dispatch = useDispatch();
  const customization = useSelector((state) => state.customization);
  const navigation = useSelector((state) => state.navigation);
  const matchesSM = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const Icon = item.icon;
  const itemIcon = item.icon ? (
    <Icon className={classes.listCustomIcon} />
  ) : (
    <FiberManualRecordIcon
      className={
        navigation.isOpen.findIndex((id) => id === item.id) > -1
          ? classes.listCustomIconSubActive
          : classes.listCustomIconSub
      }
      fontSize={level > 0 ? "inherit" : "default"}
    />
  );

  let itemIconClass = !item.icon ? classes.listIcon : classes.menuIcon;
  itemIconClass =
    customization.themeMode === "nav-dark"
      ? [itemIconClass, classes.listCustomIcon].join(" ")
      : itemIconClass;

  let itemTarget = "";
  if (item.target) {
    itemTarget = "_blank";
  }

  let listItemProps = {
    // eslint-disable-next-line no-unused-vars
    component: React.forwardRef((props, ref) => (
      <Link {...props} to={item.url} />
    )),
  };

  if (item.external) {
    listItemProps = { component: "a", href: item.url };
  }

  const itemHandler = (item) => {
    dispatch({ type: MENU_OPEN, id: convertActionToMenuOpen(item) });
    matchesSM && dispatch({ type: SET_MENU, opened: false });
  };

  // active menu item on page load
  React.useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split("/")
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      dispatch({ type: MENU_OPEN, id: convertActionToMenuOpen(item) });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <StyledListItem
      {...listItemProps}
      disabled={item.disabled}
      className={level > 1 ? classes.listItemNoBack : classes.listItem}
      sx={[
        navigation.isOpen.findIndex((id) => id.id === item.id) > -1
          ? {
              borderRight: "15px solid " + cignallThemeColor,
            }
          : {
              borderRight: null,
            },
      ]}
      selected={navigation.isOpen.findIndex((id) => id.id === item.id) > -1}
      // onClick={() => itemHandler(item.id)}
      onClick={() => itemHandler(item)}
      target={itemTarget}
      style={{ paddingLeft: level * 23 + "px" }}
    >
      <ListItemIcon className={itemIconClass}>{itemIcon}</ListItemIcon>
      <ListItemText
        primary={
          <Typography
            variant={
              navigation.isOpen.findIndex((id) => id === item.id) > -1
                ? "h5"
                : "body1"
            }
            sx={{
              color: "inherit",
            }}
          >
            {item.title}
          </Typography>
        }
        secondary={
          item.caption && (
            <Typography
              variant="caption"
              className={classes.subMenuCaption}
              gutterBottom
              sx={{
                display: "block",
              }}
            >
              {item.caption}
            </Typography>
          )
        }
      />
      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </StyledListItem>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

export default NavItem;
