// third party
import { configureStore } from "@reduxjs/toolkit";
import createDebounce from "redux-debounced";

// https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

// project imports
import rootReducer from "./store/rootReducer";

//-----------------------|| REACT DOM RENDER  ||-----------------------//

export default function configureAppStore(preloadedState) {
  const middlewares = [createDebounce()];

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(middlewares);
    },
    preloadedState,
    devTools:
      process.env.NODE_ENV === "production"
        ? false
        : {
            trace: true,
            traceLimit: 25,
          },
  });

  // if (process.env.NODE_ENV !== "production" && module.hot) {
  //   module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
  // }

  return store;
}
