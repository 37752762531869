import { fetchGet, fetchPost, fetchPut } from "utils/fetch-webapi";
import { SNACKBAR_OPEN } from "./navigationActions";

const requestClubCignallMembers = "REQUEST_CLUB_CIGNALL_MEMBERS";
const responseClubCignallMembers = "RESPONSE_CLUB_CIGNALL_MEMBERS";
const requestClubCignallMember = "REQUEST_CLUB_CIGNALL_MEMBER";
const responseClubCignallMember = "RESPONSE_CLUB_CIGNALL_MEMBER";
const requestSearchClubCignallMembers = "REQUEST_SEARCH_CLUB_CIGNALL_MEMBERS";
const responseSearchClubCignallMembers = "RESPONSE_SEARCH_CLUB_CIGNALL_MEMBERS";
const requestAddClubCignallMembers = "REQUEST_ADD_CLUB_CIGNALL_MEMBERS";
const responseAddClubCignallMembers = "RESPONSE_ADD_CLUB_CIGNALL_MEMBERS";

const requestMembersPreferences = "REQUEST_MEMBERS_PREFERENCES";
const responseMembersPreferences = "RESPONSE_MEMBERS_PREFERENCES";

const requestMembersPostcodes = "REQUEST_MEMBERS_POSTCODES";
const responseMembersPostcodes = "RESPONSE_MEMBERS_POSTCODES";
const updateMemberSearchTerm = "UPDATE_MEMBER_SEARCH_TERM";

export const updatePreferencesCategories = "REQUEST_UPDATE_PREF_CATEGORIES";
export const updatePreferencesBrands = "RESPONSE_UPDATE_PREF_BRANDS";
export const updatePreferencesProducts = "RESPONSE_UPDATE_PREF_PRODUCTS";

export const actionCreators = {
  getMemberTransferStatusList: () => async (dispatch) => {
    try {
      dispatch({ type: requestMemTransferStatusList });
      const [, response] = await fetchGet("/member-transfer-status");
      dispatch({ type: responseMemTransferStatusList, payload: response });
    } catch (e) {
      console.log("Error while loding the member transfer status list " + e);
    }
  },

  getMembersTransferRequestListByStatusId:
    (statusId, pageNo, rowsPerPage) => async (dispatch) => {
      try {
        dispatch({ type: requestMembersTransferList });
        const [, response] = await fetchPost(
          "/memberTransferRequestListByStatusId",
          {
            statusId,
            pageNo,
            rowsPerPage,
          }
        );
        dispatch({ type: responseMembersTransferList, payload: response });
      } catch (e) {
        console.log("Error while getting list " + e);
      }
    },

  getClubCignallMembers:
    (searchTerm, pageNo, rowsPerPage) => async (dispatch) => {
      try {
        dispatch({ type: requestClubCignallMembers });
        const [, response] = await fetchPost("/clubcignallmembers", {
          searchTerm,
          pageNo,
          rowsPerPage,
        });
        dispatch({
          type: responseClubCignallMembers,
          payload: response,
        });
      } catch (e) {
        console.log("Error while trying to request current user " + e);
      }
    },

  getClubCignallMemberById:
    (memberId, setListSuburbs, getStoresOnlyByState) => async (dispatch) => {
      try {
        dispatch({ type: requestClubCignallMember });
        const [, response] = await fetchGet(`/clubcignallmember/${memberId}`);
        if (response && response.member) {
          setListSuburbs(response.suburbs);
        }

        if (response.store) {
          dispatch(getStoresOnlyByState(response.store.state));
        }
        if (response.memberCategorys.length > 0) {
          dispatch({
            type: updatePreferencesCategories,
            payload: response.memberCategorys.map((p) => p.categoryId),
          });
        }
        if (response.memberBrands.length > 0) {
          dispatch({
            type: updatePreferencesBrands,
            payload: response.memberBrands.map((p) => p.brandId),
          });
        }
        if (response.memberProducts.length > 0) {
          dispatch({
            type: updatePreferencesProducts,
            payload: response.memberProducts.map((p) => p.productId),
          });
        }

        dispatch({ type: responseClubCignallMember, payload: response });
      } catch (e) {
        console.log("Error while trying to request cignall member " + e);
      }
    },

  getSuburbsByPostCode: (postCode, setListSuburbs) => async () => {
    const [, response] = await fetchGet(`/suburb/${postCode}`);
    setListSuburbs(response);
  },

  searchClubCignallMembers: (searchTerm) => async (dispatch) => {
    try {
      dispatch({ type: requestSearchClubCignallMembers });
      const [, response] = await fetchGet(`/clubcignallmembers/${searchTerm}`);
      dispatch({ type: responseSearchClubCignallMembers, payload: response });
    } catch (e) {
      console.log("Error while trying to request current user " + e);
    }
  },

  addClubCignallMembers:
    (
      values,
      preferedCategories,
      preferedBrands,
      preferredProducts,
      preferedStores,
      preferedStates,
      resetForm,
      setSubmitting
    ) =>
    async (dispatch) => {
      try {
        dispatch({ type: requestAddClubCignallMembers });
        const [, response] = await fetchPost("/clubcignallmember", {
          ...values,
          accountLocked: values.accountLocked !== "UnLocked",
          preferedCategories,
          preferedBrands,
          preferredProducts,
          preferedStores,
          preferedStates,
        });
        if (response && response.errorNo === 0) {
          resetForm();
          dispatch({ type: updatePreferencesCategories, payload: [] });
          dispatch({ type: updatePreferencesBrands, payload: [] });
        }
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: response && response.message,
          variant: "alert",
          alertSeverity: `${response.errorNo > 0 ? "error" : "success"}`,
        });
        dispatch({ type: responseAddClubCignallMembers, payload: response });

        setSubmitting(false);
      } catch (e) {
        setSubmitting(false);
      }
    },

  updateClubCignallMembers:
    (
      values,
      setSubmitting,
      navigateToMainPage,
      preferedCategories,
      preferedBrands,
      preferredProducts,
      preferedStores,
      preferedStates
    ) =>
    async (dispatch) => {
      try {
        setSubmitting(true);
        const [, response] = await fetchPut("/clubcignallmember", {
          ...values,
          accountLocked: values.accountLocked !== "UnLocked",
          preferedCategories,
          preferedBrands,
          preferredProducts,
          preferedStores,
          preferedStates,
        });

        setSubmitting(false);

        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: response.message,
          variant: "alert",
          alertSeverity: `${response.errorNo > 0 ? "error" : "success"}`,
        });
        if (response && response.errorNo === 0) {
          dispatch({ type: updatePreferencesCategories, payload: [] });
          dispatch({ type: updatePreferencesBrands, payload: [] });
          dispatch({ type: updatePreferencesProducts, payload: [] });
          setTimeout(() => navigateToMainPage(), 1000);
        }
        setSubmitting(false);
      } catch (e) {
        setSubmitting(false);
        console.log("Error while trying to create club cignall members " + e);
      }
    },

  getMembersPreferences: () => async (dispatch) => {
    try {
      dispatch({ type: requestMembersPreferences });
      const [, response] = await fetchGet("/preferences");
      dispatch({ type: responseMembersPreferences, payload: response });
    } catch (e) {
      console.log("Error while fetching Members preferences", e);
    }
  },

  getMembersPostcodes: () => async (dispatch) => {
    try {
      dispatch({ type: requestMembersPostcodes });
      const [, response] = await fetchGet("/clubcignallmemeber/postcodes");
      dispatch({ type: responseMembersPostcodes, payload: response });
    } catch (e) {
      console.log("Error while fetching members postcodes", e);
    }
  },

  resetClubCignallMemberPassword:
    (clubCignallMemberId, password, setCopyPassword) => async (dispatch) => {
      try {
        const [, response] = await fetchPut(
          "/clubcignallmember/reset-password",
          {
            clubCignallMemberId,
            password,
          }
        );
        if (response && response.errorNo === 0) {
          setCopyPassword(true);
          dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: response.message,
            variant: "alert",
            alertSeverity: `${response.errorNo > 0 ? "error" : "success"}`,
          });
        }
      } catch (e) {
        console.log("Error while trying to reset password", e);
      }
    },

  replaceClubCignallMemberNo:
    (clubCignallMemberId, memberNo, setCardReplaced, setSubmitting) =>
    async (dispatch) => {
      try {
        const [, response] = await fetchPut(
          "/clubcignallmember/replace-memberno",
          {
            clubCignallMemberId,
            memberNo,
          }
        );
        if (response) {
          if (response.errorNo === 0) {
            setCardReplaced(true);
            if (clubMember) {
              clubMember.memberNo = memberNo;
            }
          }
          dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: response.message,
            variant: "alert",
            alertSeverity: `${response.errorNo > 0 ? "error" : "success"}`,
          });
        }
      } catch (e) {
        console.log("Error while trying to replace member number", e);
      }
      setSubmitting(false);
    },

  clearClubCignallMembers: () => async (dispatch) => {
    dispatch({ type: responseClubCignallMembers, payload: [] });
  },

  clearClubCignallMember: () => async (dispatch) => {
    dispatch({ type: responseClubCignallMember, payload: null });
    dispatch({ type: updatePreferencesCategories, payload: [] });
    dispatch({ type: updatePreferencesBrands, payload: [] });
    dispatch({ type: updatePreferencesProducts, payload: [] });
  },

  setMemberSearchTerm: (term) => async (dispatch) => {
    dispatch({ type: updateMemberSearchTerm, payload: term });
  },
};

const initialState = {
  requestMembers: false,
  members: null,
  loadingMemberTransferStatus: false,
  memberTransferStatusList: null,
  gettingMemberTransferRequestList: false,
  memberTransferRequestList: null,
  clubMember: null,
  requestClubMember: false,
  requestPreferences: false,
  preferences: null,
  prefCategories: [],
  prefBrands: [],
  prefProducts: [],
  loadingPostcodes: true,
  postcodes: null,
  transferStatusId: 1,
};

export const reducer = (state, { type, payload }) => {
  state = state || initialState;
  switch (type) {
    case requestClubCignallMembers:
      return { ...state, requestMembers: true };
    case responseClubCignallMembers:
      return { ...state, requestMembers: false, members: payload };
    case requestClubCignallMember:
      return { ...state, requestClubMember: true };
    case responseClubCignallMember:
      return { ...state, requestClubMember: false, clubMember: payload };
    case requestSearchClubCignallMembers:
      return { ...state, requestMembers: true };
    case responseSearchClubCignallMembers:
      return { ...state, requestMembers: false, members: payload };
    case requestMembersPreferences:
      return { ...state, requestPreferences: true };
    case responseMembersPreferences:
      return { ...state, requestPreferences: false, preferences: payload };
    case updatePreferencesCategories:
      return { ...state, prefCategories: payload };
    case updatePreferencesBrands:
      return { ...state, prefBrands: payload };
    case updatePreferencesProducts:
      return { ...state, prefProducts: payload };
    case requestMembersPostcodes:
      return { ...state, loadingPostcodes: true };
    case responseMembersPostcodes:
      return {
        ...state,
        loadingPostcodes: false,
        postcodes: payload.postcodes,
      };
    case updateMemberSearchTerm:
      return { ...state, memberSearchTerm: payload };
    default:
      return state;
  }
};
