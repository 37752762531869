import { fetchGet, fetchPost } from "utils/fetch-webapi";
import { SNACKBAR_OPEN } from "./navigationActions";

const requestSystemSettings = "REQUEST_SYSTEM_SETTINGS";
const responseSystemSettings = "RESPONSE_SYSTEM_SETTINGS";
const requestUpdateSystemSettings = "REQUEST_UPDATE_SYSTEM_SETTINGS";
const responseUpdateSystemSettings = "RESPONSE_UPDATE_SYSTEM_SETTINGS";

export const actionCreators = {
  getSystemSettings: () => async (dispatch) => {
    dispatch({ type: requestSystemSettings });
    const [, response] = await fetchGet("/settings/system-settings");
    dispatch({ type: responseSystemSettings, response });
  },

  updateSystemSettings: (values, setSubmitting) => async (dispatch) => {
    dispatch({ type: requestUpdateSystemSettings });
    const postData = [];
    for (let key of Object.keys(values)) {
      postData.push({ systemConfigId: key, value: values[key] });
    }
    const [err, response] = await fetchPost("/settings/system-settings", {
      systemConfigs: postData,
    });

    dispatch({
      type: SNACKBAR_OPEN,
      open: true,
      message: `${err ? err : "Submit Success"}`,
      variant: "alert",
      alertSeverity: `${err ? "error" : "success"}`,
    });
    dispatch({ type: responseUpdateSystemSettings, response });
    setSubmitting(false);
  },
};

const initialState = {
  loadingSystemSettings: false,
  systemSettings: null,
  updatingSystemSettings: false,
};

export const reducer = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case requestSystemSettings:
      return { ...state, loadingSystemSettings: true };
    case responseSystemSettings:
      return {
        ...state,
        loadingSystemSettings: false,
        systemSettings: action.response,
      };
    case requestUpdateSystemSettings:
      return { ...state, updatingSystemSettings: true };
    case responseUpdateSystemSettings:
      return {
        ...state,
        updatingSystemSettings: false,
        systemSettings: action.response
          ? action.response
          : state.systemSettings,
      };
    default:
      return state;
  }
};
