// assets
import {
  PersonOutlined as IconFranchisees,
  BookmarksOutlined as IconBanners,
  ArticleOutlined as IconPosts,
  NewspaperOutlined as IconNews,
} from "@mui/icons-material";

//NOTE: URL and id of the children should have the same name
export const franchisee = {
  id: "franchisee",
  title: "Franchisees",
  type: "group",
  children: [
    {
      id: "franchisee",
      title: "Franchisee",
      type: "item",
      url: "/franchisees",
      icon: IconFranchisees,
      breadcrumbs: false,
      menuId: "1",
    },
    {
      id: "banners",
      title: "Banners",
      type: "item",
      url: "/banners",
      icon: IconBanners,
      breadcrumbs: false,
      menuId: "6",
    },
    {
      id: "franchisee-posts",
      title: "Articles & Posts",
      type: "item",
      url: "/franchisee-posts",
      icon: IconPosts,
      breadcrumbs: false,
      menuId: "7",
    },
    {
      id: "franchisee-news",
      title: "News",
      type: "item",
      url: "/franchisee-news",
      icon: IconNews,
      breadcrumbs: false,
      menuId: "8",
    },
  ],
};
//NOTE: URL and id of the children should have the same name
