import { fetchGet, fetchPost, fetchPut } from "utils/fetch-webapi";
import { SNACKBAR_OPEN } from "./navigationActions";

export const requestBroadcastMessages = "REQUEST_BROADCAST_MESSAGE";
export const responseBroadcastMessages = "RESPONSE_BROADCAST_MESSAGE";
const requestBroadcastMessageById = "REQUEST_BROADCAST_MESSAGE_BY_ID";
const responseBroadcastMessageById = "RESPONSE_BROADCAST_MESSAGE_BY_ID";

export const updateSelectedTabIndex = "UPDATE_SELECTED_TAB_INDEX";

export const actionCreators = {
  getAllBroadcastMessages:
    (
      filterExpired,
      fromFranchisee,
      action,
      pageNo,
      rowsPerPage,
      searchText,
      status
    ) =>
    async (dispatch) => {
      dispatch({ type: requestBroadcastMessages });
      let url = `/notifications/BroadcastNotifications?filterExpired=${filterExpired}&fromFranchisee=${fromFranchisee}${
        action === null ? "" : "&action=" + action
      }&pageNo=${pageNo}&rowsPerPage=${rowsPerPage}${
        searchText === null || searchText === ""
          ? ""
          : "&searchTerm=" + searchText
      }&status=${status === 1}`;
      const [, response] = await fetchGet(url);
      dispatch({ type: responseBroadcastMessages, payload: response });
    },

  getBroadcastMessageById: (notificationId) => async (dispatch) => {
    dispatch({ type: requestBroadcastMessageById });
    let url = `/notifications/BroadcastNotification/${notificationId}`;
    const [, response] = await fetchGet(url);
    dispatch({ type: responseBroadcastMessageById, payload: response });
  },

  // send broadcast message
  sendBroadcastMessage:
    (values, setSubmitting, resetForm) => async (dispatch) => {
      const [, response] = await fetchPost(
        "/notifications/BroadcastNotification",
        {
          Notification: {
            ...values,
            status: values.status === "Enable",
          },
          ConsumerTarget: values.consumerTarget
            ? values.consumerTarget
            : undefined,
          DryRun: false,
        }
      );
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: response.message,
        variant: "alert",
        alertSeverity: `${
          response && response.errorNo && response.errorNo > 0
            ? "error"
            : "success"
        }`,
      });
      setSubmitting(false);
      resetForm();
    },

  updateBroadcastMessage:
    (values, setSubmitting, resetForm, navigateToMainPage) =>
    async (dispatch) => {
      const [, response] = await fetchPut(
        "/notifications/BroadcastNotification",
        {
          Notification: {
            ...values,
            status: values.status === "Enable",
          },
          ConsumerTarget: values.consumerTarget
            ? values.consumerTarget
            : undefined,
          DryRun: false,
        }
      );

      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: response.message,
        variant: "alert",
        alertSeverity: `${
          response && response.errorNo && response.errorNo > 0
            ? "error"
            : "success"
        }`,
      });

      await setSubmitting(false);
      await resetForm();
      await navigateToMainPage();
    },
};

const initialState = {
  loading: true,
  broadcastMessages: [],
  totalCount: 0,
  message: null,
  tabIndex: 0,
};

export const reducer = (state, { type, payload }) => {
  state = state || initialState;
  switch (type) {
    case requestBroadcastMessages:
      return {
        ...state,
        loading: true,
        broadcastMessages: initialState.broadcastMessages,
      };
    case responseBroadcastMessages:
      return {
        ...state,
        loading: false,
        broadcastMessages: payload.notifications,
        totalCount: payload.totalCount,
      };
    case requestBroadcastMessageById:
      return { ...state, loading: true };
    case responseBroadcastMessageById:
      return { ...state, loading: false, message: payload };
    case updateSelectedTabIndex:
      return { ...state, tabIndex: payload };
    default:
      return state;
  }
};

//OPTIONAL: PLEASE COPY THIS TEMPLATE TO ANY NEW ACTION REDUCER YOU ARE CREATING.
