import { franchisee } from "menu-items/franchisee";
import { clubcignall } from "menu-items/clubcignall";
import { productMenu } from "menu-items/products";
import { storeMenu } from "menu-items/stores";
import { cignallweb } from "menu-items/cignallweb";
import { settings } from "menu-items/settings";
import { systemModules } from "system_modules";

//-----------------------|| MENU ITEMS ||-----------------------//

export default function buildMenuItems(currentAdmin) {
  const menus = {
    items: [],
  };

  if (!currentAdmin) {
    return menus;
  }

  //franchisee
  if (
    systemModules.HasAccessToFranchisee(currentAdmin.modules) ||
    systemModules.HasAccessToFranchiseeNews(currentAdmin.modules) ||
    systemModules.HasAccessToFranchiseePosts(currentAdmin.modules)
  ) {
    menus.items.push(franchisee);
  }

  //clubcignall
  if (
    systemModules.HasAccessToClubCignallMembers(currentAdmin.modules) ||
    systemModules.HasAccessToClubCignallPrizes(currentAdmin.modules) ||
    systemModules.HasAccessToMemberNotifications(currentAdmin.modules) ||
    systemModules.HasAccessToMembersTransfer(currentAdmin.modules)
  ) {
    menus.items.push(clubcignall);
  }

  //productMenu
  if (
    systemModules.HasAccessToCategories(currentAdmin.modules) ||
    systemModules.HasAccessToBrands(currentAdmin.modules) ||
    systemModules.HasAccessToProducts(currentAdmin.modules)
  ) {
    menus.items.push(productMenu);
  }

  //storeMenu
  if (
    systemModules.HasAccessToStores(currentAdmin.modules) ||
    systemModules.HasAccessToRegions(currentAdmin.modules)
  ) {
    menus.items.push(storeMenu);
  }

  //cignallweb
  if (systemModules.HasAccessToContactForms(currentAdmin.modules)) {
    menus.items.push(cignallweb);
  }

  // settings
  if (
    systemModules.HasAccessToAdmin(currentAdmin.modules) ||
    systemModules.HasAccessToSettings(currentAdmin.modules)
  ) {
    menus.items.push(settings);
  }

  return menus;
}
