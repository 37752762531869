export const anonymousRoutes = [
  "/login",
  "/forgot-password",
  "/reset-password",
];

export const isAnonymousRoute = (route) => {
  for (let aRoute of anonymousRoutes) {
    if (route.startsWith(aRoute)) return true;
  }
  return false;
};
