import { fetchGet, fetchPost, fetchPut } from "utils/fetch-webapi";
import { SNACKBAR_OPEN } from "./navigationActions";

export const requestAllAdmins = "REQUEST_ADMINS";
export const responseAllAdmins = "RESPONSE_ADMINS";
export const requestCreateAdmin = "REQUEST_CREATE_ADMIN";
export const requestRoles = "REQUEST_ROLES";
export const responseRoles = "RESPONSE_ROLES";
export const requestAdminById = "REQUEST_ADMIN_BY_ID";
export const responseAdminById = "RESPONSE_ADMIN_BY_ID";

export const actionCreators = {
  getAllAdmins: () => async (dispatch) => {
    dispatch({ type: requestAllAdmins });
    const [, response] = await fetchGet("/all-admins");
    dispatch({ type: responseAllAdmins, payload: response });
  },

  getAdminById: (adminId) => async (dispatch) => {
    try {
      dispatch({ type: requestAdminById });
      const [, response] = await fetchGet(`/get-admin/${adminId}`);
      dispatch({ type: responseAdminById, payload: response });
    } catch (e) {
      console.log("Error while trying to request admin by id " + e);
    }
  },

  createNewAdmins: (values, setSubmitting, resetForm) => async (dispatch) => {
    try {
      const [, response] = await fetchPost("/create-admin", { ...values });

      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: response.message,
        variant: "alert",
        alertSeverity: `${
          response && response.errorNo && response.errorNo > 0
            ? "error"
            : "success"
        }`,
      });
      if (response && response.errorNo === 0) {
        resetForm();
      }

      setSubmitting(false);
    } catch (error) {
      console.log("Error while creating new admin", error);
      setSubmitting(false);
    }
  },

  updatingAdmin:
    (values, setSubmitting, navigateToParentPage) => async (dispatch) => {
      try {
        const [, response] = await fetchPut("/update-admin", { ...values });

        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: response.message,
          variant: "alert",
          alertSeverity: `${
            response && response.errorNo && response.errorNo > 0
              ? "error"
              : "success"
          }`,
        });
        setSubmitting(false);

        if (response && response.errorNo === 0) {
          navigateToParentPage();
        }
      } catch (error) {
        console.log("Error while creating new admin", error);
        setSubmitting(false);
      }
    },
  getRoles: () => async (dispatch) => {
    try {
      dispatch({ type: requestRoles });
      const [, response] = await fetchGet("/general/admin-roles");
      dispatch({ type: responseRoles, payload: response });
    } catch (e) {
      console.log("Error while trying to get all roles " + e);
    }
  },
};

const initialState = {
  loadingAdmins: false,
  adminUsers: null,
  showDisabledAdmins: false,
  gettingAdminById: false,
  adminDetail: null,
  gettingRoles: false,
  roles: null,
};

export const reducer = (state, { type, payload }) => {
  state = state || initialState;
  switch (type) {
    case requestAllAdmins:
      return { ...state, loadingAdmins: true };
    case responseAllAdmins:
      return {
        ...state,
        loadingAdmins: false,
        adminUsers: payload,
      };
    case requestRoles:
      return { ...state, gettingRoles: true };
    case responseRoles:
      return { ...state, gettingRoles: false, roles: payload };
    case requestAdminById:
      return { ...state, gettingAdminById: true };
    case responseAdminById:
      return { ...state, gettingAdminById: false, adminDetail: payload };
    default:
      return state;
  }
};

//OPTIONAL: PLEASE COPY THIS TEMPLATE TO ANY NEW ACTION REDUCER YOU ARE CREATING.
