import { fetchGet, fetchPost, fetchPut } from "utils/fetch-webapi";
import { SNACKBAR_OPEN } from "store/navigationActions";

const requestAllStores = "REQUEST_ALL_STORES";
const responseAllStores = "RESPONSE_ALL_STORES";
const requestStoresByState = "REQUEST_STORES_BY_STATE";
const responseStoresByState = "RESPONSE_STORES_BY_STATE";
const requestStoreByStoreId = "REQUEST_STORE_BY_STORE_ID";
const responseStoreByStoreId = "RESPONSE_STORE_BY_STORE_ID";

const requestStoresFranchiseeList = "REQUEST_STORES_FRANCHISEE_LIST";
const responseStoresFranchiseeList = "RESPONSE_STORES_FRANCHISEE_LIST";

export const updateSelectedCategories = "UPDATE_SELECTED_CATEGORIES";
export const updateSelectedImage = "UPDATE_SELECTED_IMAGE";

export const updateStoreStates = "UPDATE_STORE_STATES";
export const updateStoreSearchTerm = "UPDATE_STORE_SEARCH_TERM";

export const actionCreators = {
  getAllStoresByState:
    (searchTerm, pageNo, rowsPerPage, state) => async (dispatch) => {
      try {
        dispatch({ type: requestAllStores });
        const [, response] = await fetchPost("/stores", {
          searchTerm,
          pageNo,
          rowsPerPage,
          state,
        });
        dispatch({ type: responseAllStores, payload: response });
      } catch (e) {
        console.log("Error while trying to get stores " + e);
      }
    },

  getStoresOnlyByState: (state) => async (dispatch) => {
    try {
      dispatch({ type: requestStoresByState });
      const [, response] = await fetchGet(`/stores/state/${state}`);
      dispatch({ type: responseStoresByState, payload: response });
    } catch (e) {
      console.log("Error while trying to fetch store by state", e);
    }
  },

  getAllFranchiseeList: () => async (dispatch) => {
    try {
      dispatch({ type: requestStoresFranchiseeList });
      const [, response] = await fetchGet("/all-franchisee");
      dispatch({ type: responseStoresFranchiseeList, payload: response });
    } catch (e) {
      console.log("Error while fetching Franchisee list ", e);
    }
  },

  getStoreByStoreId: (storeId) => async (dispatch) => {
    try {
      dispatch({ type: requestStoreByStoreId });
      const [, response] = await fetchGet(`/store/${storeId}`);
      dispatch({ type: responseStoreByStoreId, payload: response });

      if (response && response.store) {
        dispatch({
          type: updateSelectedImage,
          payload: response.store.mediaId,
        });
      }
      if (response && response.storeCategories.length > 0) {
        const categoryId = response.storeCategories.map(
          (category) => category.categoryId
        );
        dispatch({ type: updateSelectedCategories, payload: categoryId });
      }
    } catch (e) {
      console.log("Error while fetching Stores By store id");
    }
  },

  createStore:
    (values, selectedMedia, categories, setSubmitting, resetForm) =>
    async (dispatch) => {
      try {
        setSubmitting(true);
        const [, response] = await fetchPost("/store", {
          ...values,
          storePhoneNumber: values.storePhoneNumber.replaceAll("-", ""),
          status: values.status === "Enabled",
          mediaId: selectedMedia,
          categories,
        });
        if (response && response.errorNo === 0) {
          resetForm();
          dispatch({ type: updateSelectedImage, payload: null });
          dispatch({ type: updateSelectedCategories, payload: [] });
        }
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: response.message,
          variant: "alert",
          alertSeverity: `${response.errorNo > 0 ? "error" : "success"}`,
        });
        setSubmitting(false);
      } catch (e) {
        setSubmitting(false);
        console.log("Error while creating store", e);
      }
    },

  updateStore:
    (
      values,
      selectedMedia,
      categories,
      setSubmitting,
      resetForm,
      navigateToParentPage
    ) =>
    async (dispatch) => {
      try {
        setSubmitting(true);
        const [, response] = await fetchPut("/store", {
          ...values,
          storePhoneNumber: values.storePhoneNumber.replaceAll("-", ""),
          status: values.status === "Enabled",
          mediaId: selectedMedia,
          categories,
        });
        if (response && response.errorNo === 0) {
          resetForm();
          dispatch({ type: updateSelectedCategories, payload: [] });
          navigateToParentPage();
        }
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: response.message,
          variant: "alert",
          alertSeverity: `${response.errorNo > 0 ? "error" : "success"}`,
        });
        setSubmitting(false);
      } catch (e) {
        setSubmitting(false);
        console.log("Error while creating store", e);
      }
    },

  clearStoreList: () => async (dispatch) => {
    dispatch({ type: responseAllStores, payload: [] });
  },

  setStoreSearchTerm: (term) => (dispatch) => {
    dispatch({ type: updateStoreSearchTerm, payload: term });
  },
};

const initialState = {
  gettingStores: false,
  stores: null,
  storesByStateList: null,
  selectedCategories: [],
  storesFranchisees: null,
  gettingStoresFranchisee: false,
  storeById: null,
  gettingStoreById: false,
  selectedMedia: null,
  retainStoreState: {
    tab: 0,
  },
  storeSearchTerm: "",
};

export const reducer = (state, { type, payload }) => {
  state = state || initialState;
  switch (type) {
    case requestAllStores:
      return { ...state, gettingStores: true, stores: null };
    case responseAllStores:
      return { ...state, gettingStores: false, stores: payload };
    case requestStoresByState:
      return { ...state, gettingStores: true, storesByStateList: null };
    case responseStoresByState:
      return { ...state, gettingStores: false, storesByStateList: payload };
    case updateSelectedCategories:
      return { ...state, selectedCategories: payload };
    case updateSelectedImage:
      return { ...state, selectedMedia: payload };
    case requestStoresFranchiseeList:
      return {
        ...state,
        gettingStoresFranchisee: true,
        storesFranchisees: null,
      };
    case responseStoresFranchiseeList:
      return {
        ...state,
        gettingStoresFranchisee: false,
        storesFranchisees: payload,
      };
    case requestStoreByStoreId:
      return { ...state, gettingStoreById: true };
    case responseStoreByStoreId:
      return { ...state, gettingStoreById: false, storeById: payload };
    case updateStoreStates:
      return { ...state, retainStoreState: payload };
    case updateStoreSearchTerm:
      return { ...state, storeSearchTerm: payload };
    default:
      return state;
  }
};
