import { fetchGet, fetchPost, fetchPut } from "utils/fetch-webapi";
import { SNACKBAR_OPEN } from "./navigationActions";
import { isValidInt } from "utils/input-sanitize";

const requestFNList = "REQUEST_FN_LIST";
const responseFNList = "RESPONSE_FN_LIST";
const requestFNByNewsId = "REQUEST_FN_BY_NEWS_ID";
const responseFNByNewsId = "RESPONSE_FN_BY_NEWS_ID";
const requestAcknowledgementsList = "REQUEST_ACKNOWLEDGEMENTS_LIST";
const responseAcknowledgementsList = "RESPONSE_ACKNOWLEDGEMENTS_LIST";
const resetNewsToInitialState = "RESET_NEWS_TO_INITIAL_STATE";
const updateNewsLoading = "UPDATE_NEWS_LOADING";
const updateNewsNotFound = "UPDATE_NEWS_NOT_FOUND";
const updateNewsSearchCriteria = "UPDATE_NEWS_SEARCH_CRITERIA";
export const updateFranchiseeSelectedTab = "UPDATE_FRANCHISEE_NEWS_TAB";

export const actionCreators = {
  getFranchiseeNewsList:
    (searchTerm, loadContent, pageNo, rowsPerPage, searchType) =>
    async (dispatch) => {
      try {
        dispatch({ type: requestFNList });
        const [, response] = await fetchPost("/franchisee-news-list", {
          searchTerm,
          loadContent,
          pageNo,
          rowsPerPage,
          searchType,
        });
        dispatch({ type: responseFNList, payload: response });
      } catch (e) {
        dispatch({
          type: updateNewsLoading,
          payload: false,
        });
        console.log("Error while trying to request Franchisee News " + e);
      }
    },

  getFranchiseeNewsById: (franchiseeNewsId) => async (dispatch) => {
    try {
      if (isValidInt(franchiseeNewsId)) {
        dispatch({ type: updateNewsNotFound, payload: false });
        dispatch({ type: requestFNByNewsId });
        const [, response] = await fetchGet(
          `/franchisee-news/${franchiseeNewsId}`
        );
        dispatch({ type: responseFNByNewsId, payload: response });
      } else {
        dispatch({ type: updateNewsNotFound, payload: true });
      }
    } catch (e) {
      dispatch({ type: updateNewsNotFound, payload: true });
      console.log("Error while trying to retrieve Franchisee News " + e);
    }
  },

  createFranchiseeNews:
    (values, setSubmitting, resetForm) => async (dispatch) => {
      try {
        setSubmitting(true);
        const [, response] = await fetchPost("/franchisee-news", {
          ...values,
        });
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: response.message,
          variant: "alert",
          alertSeverity: `${response.errorNo > 0 ? "error" : "success"}`,
        });
        if (response.errorNo === 0) {
          resetForm();
        }
        setSubmitting(false);
      } catch (e) {
        setSubmitting(false);
        console.log("Error while trying to create Franchisee News");
      }
    },

  updateFranchiseeNews:
    (values, setSubmitting, resetForm, navigateToMainPage) =>
    async (dispatch) => {
      try {
        setSubmitting(true);
        const [, response] = await fetchPut("/franchisee-news", {
          ...values,
        });
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: response.message,
          variant: "alert",
          alertSeverity: `${response.errorNo > 0 ? "error" : "success"}`,
        });
        if (response.errorNo === 0) {
          resetForm();
          navigateToMainPage();
        }
        setSubmitting(false);
      } catch (e) {
        setSubmitting(false);
        console.log("Error while trying to create Franchisee News");
      }
    },
  getAcknowledgementsHistory:
    (searchParam, searchType, searchTerm, pageNo, rowsPerPage) =>
    async (dispatch) => {
      try {
        dispatch({ type: requestAcknowledgementsList });
        const [, response] = await fetchPost(
          "/franchisee-news/acknowledgement",
          {
            id: searchParam,
            searchType,
            searchTerm,
            pageNo,
            rowsPerPage,
          }
        );
        dispatch({ type: responseAcknowledgementsList, payload: response });
      } catch (e) {
        console.log("Error while trying to request Acknowledgements " + e);
      }
    },
  setNewsToInitialState: () => async (dispatch) => {
    dispatch({ type: resetNewsToInitialState });
  },
  setNewsSearchCriteria: (criteria) => (dispatch) => {
    dispatch({
      type: updateNewsSearchCriteria,
      payload: {
        searchTerm: criteria.searchTerm,
        pageNo: criteria.pageNo,
        rowsPerPage: criteria.rowsPerPage,
      },
    });
  },
};

const initialState = {
  newsLists: null,
  loading: false,
  gettingNews: false,
  news: null,
  tabIndex: 0,
  gettingAcknowledgement: false,
  acknowledgements: null,
  newsNotFound: false,
  newsSearchCriteria: { searchTerm: "", pageNo: 1, rowsPerPage: 10 },
};

export const reducer = (state, { type, payload }) => {
  state = state || initialState;
  switch (type) {
    case requestFNList:
      return { ...state, loading: true };
    case responseFNList:
      return { ...state, loading: false, fnLists: payload };
    case updateFranchiseeSelectedTab:
      return { ...state, tabIndex: payload };
    case requestFNByNewsId:
      return { ...state, gettingNews: true };
    case responseFNByNewsId:
      return {
        ...state,
        gettingNews: false,
        news: payload,
        newsNotFound:
          payload === null || payload === undefined || payload === "",
      };
    case requestAcknowledgementsList:
      return { ...state, gettingAcknowledgement: true };
    case responseAcknowledgementsList:
      return {
        ...state,
        gettingAcknowledgement: false,
        acknowledgements: payload,
      };
    case resetNewsToInitialState:
      return { ...state, news: null };
    case updateNewsNotFound:
      return { ...state, newsNotFound: payload };
    case updateNewsSearchCriteria:
      return { ...state, newsSearchCriteria: payload };
    case updateNewsLoading:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
};
