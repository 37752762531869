// assets
import {
  StorefrontOutlined as IconBuildingStore,
  Language as IconWorld,
} from "@mui/icons-material";

//NOTE: URL and id of the children should have the same name
export const storeMenu = {
  id: "store-admin",
  title: "Stores",
  type: "group",
  children: [
    {
      id: "stores",
      title: "Stores",
      type: "item",
      url: "/stores",
      icon: IconBuildingStore,
      breadcrumbs: false,
      menuId: "2",
    },
    {
      id: "regions",
      title: "Regions",
      type: "item",
      url: "/regions",
      icon: IconWorld,
      breadcrumbs: false,
      menuId: "3",
    },
  ],
};
//NOTE: URL and id of the children should have the same name
