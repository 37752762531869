import { fetchDelete, fetchGet, fetchPost, fetchPut } from "utils/fetch-webapi";
import { SNACKBAR_OPEN } from "./navigationActions";

const requestCategories = "REQUEST_CATEGORIES";
const responseCategories = "RESPONSE_CATEGORIES";
const requestCategory = "REQUEST_CATEGORY";
const responseCategory = "RESPONSE_CATEGORY";
const requestAllCategories = "REQUEST_ALL_CATEGORIES";
const responseAllCategories = "RESPONSE_ALL_CATEGORIES";
const updateCategoriesSearchTerm = "UPDATE_CATEGORIES_SEARCH_TERM";

export const actionCreators = {
  getCategories: () => async (dispatch) => {
    try {
      dispatch({ type: requestCategories });
      const [, response] = await fetchGet("/categories");
      dispatch({ type: responseCategories, payload: response });
    } catch (e) {
      console.log("Error while trying to request category " + e);
    }
  },

  getCategoryById: (categoryId) => async (dispatch) => {
    try {
      dispatch({ type: requestCategory });
      const [, response] = await fetchGet(`/category/${categoryId}`);
      dispatch({ type: responseCategory, payload: response });
    } catch (e) {
      console.log("Error while trying to get category " + e);
    }
  },

  getAllCategories: (searchTerm, pageNo, rowsPerPage) => async (dispatch) => {
    try {
      dispatch({ type: requestAllCategories });
      const [, response] = await fetchPost("/list-categories", {
        searchTerm,
        pageNo,
        rowsPerPage,
      });
      dispatch({ type: responseAllCategories, payload: response });
    } catch (e) {
      console.log("Error while trying to request categories " + e);
    }
  },

  addCategory: (values, setSubmitting, resetForm) => async (dispatch) => {
    try {
      setSubmitting(false);
      const [, response] = await fetchPost("/categories", {
        ...values,
        ccMemberAvailable: values.ccMemberAvailable,
        franchiseeAvailable: values.franchiseeAvailable,
        status: values.status,
      });
      if (response && response.errorNo === 0) resetForm();
      setSubmitting(false);
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: response.message,
        variant: "alert",
        alertSeverity: `${response.errorNo > 0 ? "error" : "success"}`,
      });
    } catch (e) {
      setSubmitting(false);
      console.log("Error while adding category ", e);
    }
  },

  updateCategory: (values, setSubmitting, navigateBack) => async (dispatch) => {
    setSubmitting(true);
    try {
      const [, response] = await fetchPut("/categories", {
        ...values,
        ccMemberAvailable: values.ccMemberAvailable,
        franchiseeAvailable: values.franchiseeAvailable,
        status: values.status,
      });
      if (response && response.errorNo === 0) {
        navigateBack();
      }
      setSubmitting(false);
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: response.message,
        variant: "alert",
        alertSeverity: `${response.errorNo > 0 ? "error" : "success"}`,
      });
    } catch (e) {
      setSubmitting(false);
      console.log("Error while adding category ", e);
    }
  },

  deleteCategory: (categoryId, navigateBack) => async (dispatch) => {
    const [, response] = await fetchDelete(`/categories/${categoryId}`);
    if (response && response.errorNo === 0) navigateBack();
    dispatch({
      type: SNACKBAR_OPEN,
      open: true,
      message: response.message,
      variant: "alert",
      alertSeverity: `${response.errorNo > 0 ? "error" : "success"}`,
    });
  },

  setCategoriesSearchTerm: (term) => async (dispatch) => {
    dispatch({ type: updateCategoriesSearchTerm, payload: term });
  },
};

const initialState = {
  requestCategories: false,
  categories: null,
  requestAllCategories: false,
  allCategories: null,
  gettingCategory: false,
  category: null,
  categoriesSearchTerm: "",
};

export const reducer = (state, { type, payload }) => {
  state = state || initialState;
  switch (type) {
    case requestCategories:
      return { ...state, requestCategories: true };
    case responseCategories:
      return { ...state, requestCategories: false, categories: payload };
    case requestAllCategories:
      return { ...state, requestAllCategories: true };
    case responseAllCategories:
      return { ...state, requestAllCategories: false, allCategories: payload };
    case requestCategory:
      return { ...state, gettingCategory: true };
    case responseCategory:
      return { ...state, gettingCategory: false, category: payload };
    case updateCategoriesSearchTerm:
      return { ...state, categoriesSearchTerm: payload };
    default:
      return state;
  }
};
