// This is also common config, used by all environments
const commonConfig = {};

const devConfig = {
  API_DOMAIN: "http://localhost:7071/api",
  PUBLIC_IMAGE_DOMAIN: "https://cignallwebuploadsdev.z8.web.core.windows.net/",
};

const azureDevConfig = {
  API_DOMAIN: "https://ac-admin-api-dev.azurewebsites.net/api",
  PUBLIC_IMAGE_DOMAIN: "https://cignallwebuploadsdev.z8.web.core.windows.net/",
};

const prodConfig = {
  API_DOMAIN: "https://ac-admin-api-prod.azurewebsites.net/api",
  PUBLIC_IMAGE_DOMAIN: "https://cignallwebuploadsprod.z8.web.core.windows.net/",
};

const testConfig = {
  API_DOMAIN: "https://ac-admin-api-test.azurewebsites.net/api",
  PUBLIC_IMAGE_DOMAIN: "https://cignallwebuploadsdev.z8.web.core.windows.net/",
};

const uatConfig = {
  API_DOMAIN: "https://ac-admin-api-uat.azurewebsites.net/api",
  PUBLIC_IMAGE_DOMAIN: "https://cignallwebuploadsdev.z8.web.core.windows.net/",
};

const env = process.env.REACT_APP_ENV_NAME;

export default env === "development"
  ? { ...commonConfig, ...devConfig }
  : env === "azuredev"
    ? { ...commonConfig, ...azureDevConfig }
    : env === "production"
      ? { ...commonConfig, ...prodConfig }
      : env === "test"
        ? { ...commonConfig, ...testConfig }
        : env === "uat"
          ? { ...commonConfig, ...uatConfig }
          : commonConfig;
