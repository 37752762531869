import React, { lazy } from "react";

// third-party library
import { Route, Routes } from "react-router-dom";
//import { useSelector } from "react-redux";

// project imports
//import GuestGuard from "./../utils/route-guard/GuestGuard";
import MinimalLayout from "./../layout/MinimalLayout";
import NavMotion from "./../layout/NavMotion";
import Loadable from "../ui-component/Loadable";

// login routing
const AuthLogin = Loadable(
  lazy(() => import("../views/pages/authentication/login"))
);
const ForgotPassword = Loadable(
  lazy(() => import("../views/pages/authentication/forgot-password"))
);
const AdminResetPassword = Loadable(
  lazy(
    () => import("../views/pages/authentication/reset-password/ResetPassword")
  )
);

//-----------------------|| AUTH ROUTING ||-----------------------//

const LoginRoutes = () => {
  //const { currentAdmin } = useSelector((state) => state.authentication);
  return (
    <MinimalLayout>
      <Routes>
        <Route element={<NavMotion />}>
          {/* <Route element={<GuestGuard admin={currentAdmin} />}> */}
          <Route path="/login" element={<AuthLogin />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/reset-password/:token"
            element={<AdminResetPassword />}
          />
          {/* <Route path="*" element={<Navigate to="/login" />} /> */}
          {/* </Route> */}
        </Route>
      </Routes>
    </MinimalLayout>
  );
};

export default LoginRoutes;
