import React from "react";

// mui-material
import {
  Avatar,
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid2,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

// mui-icons
import {
  Logout as IconLogout,
  SettingsOutlined as IconSettings,
  Repeat as IconRepeat,
} from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// state management
import { useSelector, useDispatch } from "react-redux";
import {
  MENU_OPEN,
  getChangePasswordMenuOpen,
  getUserPreferencesMenuOpen,
} from "store/navigationActions";
import { responseCurrentAdmin } from "store/authenticationActionReducer";

// third-party library
import { useNavigate } from "react-router-dom";

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import { clearAuthToken, getAuthToken } from "utils/fetch-webapi";

// style constant
const PREFIX = "ProfileSection";

const classes = {
  navContainer: `${PREFIX}-navContainer`,
  small: `${PREFIX}-small`,
  medium: `${PREFIX}-medium`,
  large: `${PREFIX}-large`,
  listItem: `${PREFIX}-listItem`,
  cardContent: `${PREFIX}-cardContent`,
  flex: `${PREFIX}-flex`,
  box: `${PREFIX}-box`,
  logoutTextColor: `${PREFIX}-logoutTextColor`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.navContainer}`]: {
    width: "100%",
    maxWidth: "350px",
    minWidth: "300px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    [theme.breakpoints.down("md")]: {
      minWidth: "100%",
    },
  },

  [`& .${classes.small}`]: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },

  [`& .${classes.medium}`]: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },

  [`& .${classes.large}`]: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },

  [`& .${classes.listItem}`]: {
    marginTop: "5px",
  },

  [`& .${classes.cardContent}`]: {
    padding: "16px !important",
  },

  [`& .${classes.flex}`]: {
    display: "flex",
  },

  [`& .${classes.box}`]: {
    marginLeft: "16px",
    marginRight: "24px",
    [theme.breakpoints.down("md")]: {
      marginRight: "16px",
    },
  },

  [`& .${classes.logoutTextColor}`]: {
    color: "#FF0000",
  },
}));

//-----------------------|| PROFILE MENU ||-----------------------//

const ProfileSection = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customization = useSelector((state) => state.customization);
  const { currentAdmin } = useSelector((state) => state.authentication);

  const [selectedIndex] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleLogout = async () => {
    try {
      clearAuthToken();
      localStorage.removeItem("persist:root");
      dispatch({ type: responseCurrentAdmin, payload: null });
      if (!getAuthToken()) {
        navigate("/login", { replace: true });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleChangePassword = async () => {
    dispatch({
      type: MENU_OPEN,
      id: getChangePasswordMenuOpen(),
    });
    navigate("/change-password");
    setOpen(false);
  };

  const handlePreferences = async () => {
    dispatch({
      type: MENU_OPEN,
      id: getUserPreferencesMenuOpen(),
    });
    navigate("/user-preferences");
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Root>
      <Box component="span" className={classes.box}>
        <ButtonBase
          sx={{ borderRadius: "12px" }}
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <Grid2 container direction="row">
            <Grid2 size="auto">
              <Avatar>
                {currentAdmin &&
                  currentAdmin.firstName.substring(0, 1) +
                    currentAdmin.lastName.substring(0, 1)}
              </Avatar>
            </Grid2>
            <Grid2>
              <ExpandMoreIcon className={classes.medium} />
            </Grid2>
          </Grid2>
        </ButtonBase>
      </Box>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <CardContent className={classes.cardContent}>
                    <Grid2 container direction="column" spacing={0}>
                      <Grid2 className={classes.flex}>
                        <Typography component="span" variant="h4">
                          {currentAdmin &&
                            currentAdmin.firstName +
                              " " +
                              currentAdmin.lastName}
                        </Typography>
                      </Grid2>
                    </Grid2>

                    <List component="nav" className={classes.navContainer}>
                      <ListItemButton
                        className={classes.listItem}
                        sx={{
                          borderRadius: customization.borderRadius + "px",
                        }}
                        selected={selectedIndex === 0}
                        onClick={handleChangePassword}
                      >
                        <ListItemIcon>
                          <IconRepeat />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="body2">
                              Change Password
                            </Typography>
                          }
                        />
                      </ListItemButton>
                      <ListItemButton
                        className={classes.listItem}
                        sx={{
                          borderRadius: customization.borderRadius + "px",
                        }}
                        selected={selectedIndex === 0}
                        onClick={handlePreferences}
                      >
                        <ListItemIcon>
                          <IconSettings />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="body2">Preferences</Typography>
                          }
                        />
                      </ListItemButton>
                      <ListItemButton
                        className={classes.listItem}
                        sx={{
                          borderRadius: customization.borderRadius + "px",
                        }}
                        selected={selectedIndex === 1}
                        onClick={handleLogout}
                      >
                        <ListItemIcon>
                          <IconLogout className={classes.logoutTextColor} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body2"
                              className={classes.logoutTextColor}
                            >
                              Logout
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </List>
                  </CardContent>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Root>
  );
};

export default ProfileSection;
