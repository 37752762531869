import { fetchGet, fetchPost, fetchPut, fetchDelete } from "utils/fetch-webapi";
import { SNACKBAR_OPEN } from "./navigationActions";
import { isValidInt } from "utils/input-sanitize";

export const types = {
  getAllFranchiseePosts: "GET_ALL_FRANCHISEE_POSTS",
  getAllFranchiseePostsResponse: "GET_ALL_FRANCHISEE_POSTS_RESPONSE",

  requestFranchiseePostsTypesAndTargets:
    "REQUEST_FRANCHISEE_POSTS_TYPES_AND_TARGETS",
  responseFranchiseePostsTypesAndTargets:
    "RESPONSE_FRANCHISEE_POSTS_TYPES_AND_TARGETS",

  requestFranchiseeTargetDescList: "REQUEST_FRANCHISEE_TARGET_DESC_LIST",
  responseFranchiseeTargetDescList: "RESPONSE_FRANCHISEE_TARGET_DESC_LIST",
  resetFranchiseeTargetDescList: "RESET_FRANCHISEE_TARGET_DESC_LIST",

  getFranchiseePostById: "GET_FRANCHISEE_POST_BY_ID",
  getFranchiseePostByIdResponse: "GET_FRANCHISEE_POST_BY_ID_RESPONSE",

  deleteFranchiseePost: "DELETE_FRANCHISEE_POST",
  deleteFranchiseePostFile: "DELETE_FRANCHISEE_POST_FILE",
  updateFranchiseePost: "UPDATE_FRANCHISEE_POST",
  updateFranchiseePosts: "UPDATE_FRANCHISEE_POSTS",
  updateTotalCount: "UPDATE_TOTAL_COUNT",
  updateSelectedMedia: "UPDATE_SELECTED_MEDIA",
  resetSelectedMedia: "RESET_SELECTED_MEDIA",
  updatePostNotFound: "UPDATE_POST_NOT_FOUND",
  updatePostsSearchCriteria: "UPDATE_POSTS_SEARCH_CRITERIA",
  updatePostsLoading: "UPDATE_POSTS_LOADING",

  requestAcknowledgementsList: "REQUEST_ACKNOWLEDGEMENTS_LIST",
  responseAcknowledgementsList: "RESPONSE_ACKNOWLEDGEMENTS_LIST",
};

export const actionCreators = {
  getAllFranchiseePosts:
    (
      searchTerm,
      loadMessage = false,
      pageNo = 1,
      rowsPerPage = 10,
      isDraft = false,
      activeFlg
    ) =>
    async (dispatch) => {
      try {
        dispatch({ type: types.getAllFranchiseePosts });

        // query string
        var queryStr = "?";
        if (searchTerm) queryStr += `searchTerm=${searchTerm}&`;
        queryStr += `loadMessage=${loadMessage.toString()}&`;
        if (pageNo) queryStr += `pageNo=${pageNo}&`;
        if (rowsPerPage) queryStr += `rowsPerPage=${rowsPerPage}&`;
        queryStr += `isDraft=${isDraft.toString()}&`;
        if (activeFlg) queryStr += `activeFlg=${activeFlg.toString()}&`;
        if (queryStr === "?") queryStr = "";

        const [, response] = await fetchGet("/franchisee-posts" + queryStr);

        dispatch({
          type: types.getAllFranchiseePostsResponse,
          payload: response,
        });
      } catch (e) {
        dispatch({
          type: types.updatePostsLoading,
          payload: false,
        });
        console.log("Error while trying to get regions " + e);
      }
    },

  getFranchiseePostById: (id) => async (dispatch) => {
    try {
      if (isValidInt(id)) {
        dispatch({ type: types.updatePostNotFound, payload: false });
        dispatch({ type: types.getFranchiseePostById });

        const [, response] = await fetchGet("/franchisee-posts/" + id);
        dispatch({
          type: types.getFranchiseePostByIdResponse,
          payload: response,
        });
      } else {
        dispatch({ type: types.updatePostNotFound, payload: true });
      }
    } catch (e) {
      dispatch({ type: types.updatePostNotFound, payload: true });
      console.log("Error while trying to get franchisee post " + e);
    }
  },

  getFranchiseePostTypeAndTargets: () => async (dispatch) => {
    try {
      dispatch({ type: types.requestFranchiseePostsTypesAndTargets });
      const [, response] = await fetchGet("/posts-target-types");
      dispatch({
        type: types.responseFranchiseePostsTypesAndTargets,
        payload: response,
      });
    } catch (e) {
      dispatch({
        type: types.responseFranchiseePostsTypesAndTargets,
        payload: response,
      });
      console.log("Error while trying to fetch Post types and targets", e);
    }
  },

  getFranchiseeTargetDescList: (targetId, targets) => async (dispatch) => {
    try {
      dispatch({ type: types.requestFranchiseeTargetDescList });
      const [, response] = await fetchPost("/franchisee-target-list-desc", {
        targetId: targetId,
        targets: targets,
      });
      dispatch({
        type: types.responseFranchiseeTargetDescList,
        payload: response.targets,
      });
    } catch (e) {
      dispatch({
        type: types.responseFranchiseeTargetDescList,
        payload: response,
      });
      console.log("Error while trying to fetch Target List Descriptions", e);
    }
  },

  createOrUpdateFranchiseePost:
    (values, selectedMedia, setSubmitting, resetForm, navigateOnSuccess) =>
    async (dispatch) => {
      var exceptionRaised = false;
      try {
        setSubmitting(true);
        if (values.id) {
          const [, response] = await fetchPut("/franchisee-post", {
            ...values,
            mediaId: selectedMedia && selectedMedia.toString(),
          });
          if (response && response.errorNo === 0) {
            resetForm();
            navigateOnSuccess();
          }
          dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: response.message,
            variant: "alert",
            alertSeverity: `${response.errorNo > 0 ? "error" : "success"}`,
          });
        } else {
          const [, response] = await fetchPost("/franchisee-post", {
            ...values,
            mediaId: selectedMedia && selectedMedia.toString(),
          });
          if (response && response.errorNo === 0) {
            resetForm();
            navigateOnSuccess();
          }
          dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: response.message,
            variant: "alert",
            alertSeverity: `${response.errorNo > 0 ? "error" : "success"}`,
          });
        }
      } catch (e) {
        exceptionRaised = true;
        console.log("Error while create/update franchisee post");
      } finally {
        setSubmitting(false);
        if (exceptionRaised) {
          dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: "Exception during create/update franchisee post",
            variant: "alert",
            alertSeverity: "error",
          });
        }
      }
    },

  getAcknowledgementsHistory:
    (
      franchiseePostId,
      postTarget,
      targetDesc,
      searchType,
      searchTerm,
      pageNo,
      rowsPerPage
    ) =>
    async (dispatch) => {
      try {
        dispatch({ type: types.requestAcknowledgementsList });
        const [, response] = await fetchPost(
          "/franchisee-post/acknowledgement",
          {
            id: franchiseePostId,
            postTargetId: postTarget,
            targets: targetDesc,
            searchType,
            searchTerm,
            pageNo,
            rowsPerPage,
          }
        );
        dispatch({
          type: types.responseAcknowledgementsList,
          payload: response,
        });
      } catch (e) {
        console.log("Error while trying to request Acknowledgements " + e);
      }
    },

  deleteFranchiseePost: (id) => async (dispatch) => {
    try {
      const [, response] = await fetchDelete(`/franchisee-posts/${id}`);
      if (response && response.errorNo === 0) {
        dispatch({
          type: types.deleteFranchiseePost,
          payload: { id },
        });
      }
    } catch (e) {
      console.log("Error while trying to delete post " + e);
    }
  },

  deleteFranchiseePostFile: (id) => async (dispatch) => {
    try {
      const [, response] = await fetchDelete(`/franchisee-posts/file/${id}`);
      if (response && response.errorNo === 0) {
        dispatch({
          type: types.deleteFranchiseePostFile,
          payload: { id },
        });
      }
    } catch (e) {
      console.log("Error while trying to delete post " + e);
    }
  },

  setPostsSearchCriteria: (criteria) => (dispatch) => {
    dispatch({
      type: types.updatePostsSearchCriteria,
      payload: {
        searchTerm: criteria.searchTerm,
        pageNo: criteria.pageNo,
        rowsPerPage: criteria.rowsPerPage,
      },
    });
  },

  resetInitialState: () => (dispatch) => {
    dispatch({
      type: types.updateFranchiseePost,
      payload: null,
    });
    dispatch({
      type: types.resetSelectedMedia,
      payload: null,
    });
    dispatch({
      type: types.resetFranchiseeTargetDescList,
      payload: null,
    });
  },
};

const initialState = {
  loading: false,
  postsTypesTargets: null,
  targetDescList: [],
  franchiseePosts: [],
  franchiseePost: null,
  selectedMedia: null,
  totalCount: 0,
  gettingAcknowledgement: false,
  postNotFound: false,
  postsSearchCriteria: { searchTerm: "", pageNo: 1, rowsPerPage: 10 },
};

export const reducer = (state, { type, payload }) => {
  state = state || initialState;

  switch (type) {
    case types.requestFranchiseePostsTypesAndTargets:
      return { ...state };
    case types.responseFranchiseePostsTypesAndTargets:
      return {
        ...state,
        postsTypesTargets: payload,
      };
    case types.requestFranchiseeTargetDescList:
      return { ...state };
    case types.responseFranchiseeTargetDescList:
      return {
        ...state,
        targetDescList: payload,
      };
    case types.resetFranchiseeTargetDescList:
      return { ...state, targetDescList: [] };
    case types.getAllFranchiseePosts:
      return {
        ...state,
        loading: true,
        franchiseePosts: [],
        totalCount: 0,
      };
    case types.getAllFranchiseePostsResponse:
      return {
        ...state,
        loading: false,
        franchiseePosts: payload.franchiseePosts,
        totalCount: payload.totalCount,
      };
    case types.getFranchiseePostById:
      return { ...state, loading: true };
    case types.getFranchiseePostByIdResponse:
      return {
        ...state,
        loading: false,
        franchiseePost: payload,
        postNotFound:
          payload === null || payload === undefined || payload === "",
      };
    case types.deleteFranchiseePost:
      return {
        ...state,
        franchiseePosts: state.franchiseePosts.filter(
          (fn) => fn.id !== payload.id
        ),
      };
    case types.deleteFranchiseePostFile:
      return {
        ...state,
      };
    case types.updateFranchiseePost:
      return { ...state, franchiseePost: payload };
    case types.updateFranchiseePosts:
      return { ...state, franchiseePosts: payload };
    case types.updateTotalCount:
      return { ...state, totalCount: payload };
    case types.updateSelectedMedia:
      return { ...state, selectedMedia: payload };
    case types.resetSelectedMedia:
      return { ...state, selectedMedia: null };
    case types.updatePostNotFound:
      return { ...state, postNotFound: payload };
    case types.updatePostsSearchCriteria:
      return { ...state, postsSearchCriteria: payload };
    case types.updatePostsLoading:
      return {
        ...state,
        loading: payload,
      };

    case types.requestAcknowledgementsList:
      return { ...state, gettingAcknowledgement: true };
    case types.responseAcknowledgementsList:
      return {
        ...state,
        gettingAcknowledgement: false,
        acknowledgements: payload,
      };
    default:
      return state;
  }
};
