/* eslint-disable react/display-name */
import React from "react";

// mui-material
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// third-party library
import PropTypes from "prop-types";

// style constant
const headerSX = {
  "& .MuiCardHeader-action": { mr: 0 },
};

//-----------------------|| CUSTOM MAIN CARD ||-----------------------//

const MainCard = React.forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      content = true,
      contentClass,
      contentSX,
      darkTitle,
      secondary,
      shadow,
      sx = {},
      title,
      ...others
    },
    ref
  ) => {
    const theme = useTheme();

    return (
      <Card
        ref={ref}
        {...others}
        sx={[
          (theme) => ({
            borderColor: theme.palette.primary[200] + 75,

            ...sx,

            ...theme.applyStyles("dark", {
              borderColor: theme.palette.background.default,
            }),
          }),
          border
            ? {
                border: "1px solid",
              }
            : {
                border: "none",
              },
          boxShadow
            ? {
                ":hover": {
                  boxShadow: shadow
                    ? shadow
                    : theme.palette.mode === "dark"
                      ? "0 2px 14px 0 rgb(33 150 243 / 10%)"
                      : "0 2px 14px 0 rgb(32 40 45 / 8%)",
                },
              }
            : {
                ":hover": {
                  boxShadow: "inherit",
                },
              },
        ]}
      >
        {/* card header and action */}
        {!darkTitle && title && (
          <CardHeader sx={headerSX} title={title} action={secondary} />
        )}
        {darkTitle && title && (
          <CardHeader
            sx={headerSX}
            title={<Typography variant="h3">{title}</Typography>}
            action={secondary}
          />
        )}
        {/* content & header divider */}
        {title && <Divider />}
        {/* card content */}
        {content && (
          <CardContent sx={contentSX} className={contentClass}>
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    );
  }
);

MainCard.propTypes = {
  border: PropTypes.bool,
  boxShadow: PropTypes.bool,
  children: PropTypes.node,
  content: PropTypes.bool,
  contentClass: PropTypes.string,
  contentSX: PropTypes.object,
  darkTitle: PropTypes.bool,
  secondary: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
  ]),
  shadow: PropTypes.string,
  sx: PropTypes.object,
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
  ]),
};

export default MainCard;
