// assets
import { ListAltOutlined as IconClipboardList } from "@mui/icons-material";

//NOTE: URL and id of the children should have the same name
export const cignallweb = {
  id: "cignallweb",
  title: "Cignall Website",
  type: "group",
  children: [
    {
      id: "contacts",
      title: "Contact Forms",
      type: "item",
      url: "/contacts",
      icon: IconClipboardList,
      breadcrumbs: false,
      menuId: "11",
    },
  ],
};
//NOTE: URL and id of the children should have the same name
