// assets
import {
  CategoryOutlined as IconCategories,
  BrandingWatermarkOutlined as IconBrands,
  Inventory2Outlined as IconProducts,
} from "@mui/icons-material";

//NOTE: URL and id of the children should have the same name
export const productMenu = {
  id: "product-admin",
  title: "Products",
  type: "group",
  children: [
    {
      id: "categories",
      title: "Categories",
      type: "item",
      url: "/categories",
      icon: IconCategories,
      breadcrumbs: false,
      menuId: "4",
    },
    {
      id: "brands",
      title: "Brands",
      type: "item",
      url: "/brand/add",
      icon: IconBrands,
      breadcrumbs: false,
      menuId: "5",
    },
    {
      id: "products",
      title: "Products",
      type: "item",
      url: "/products",
      icon: IconProducts,
      breadcrumbs: false,
      menuId: "15",
    },
  ],
};
//NOTE: URL and id of the children should have the same name
