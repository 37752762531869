// assets
import {
  PeopleOutlined as IconMembers,
  MailOutlined as IconPrizes,
  CastOutlined as IconNotifications,
  TransferWithinAStationOutlined as IconTransferMember,
} from "@mui/icons-material";

//NOTE: URL and id of the children should have the same name
export const clubcignall = {
  id: "clubcignall",
  title: "Club Cignall",
  type: "group",
  children: [
    {
      id: "clubcignallmembers",
      title: "Members",
      type: "item",
      url: "/clubcignallmembers",
      icon: IconMembers,
      breadcrumbs: false,
      menuId: "0",
    },
    {
      id: "club-cignall-prize",
      title: "Prizes",
      type: "item",
      url: "/club-cignall-prize",
      icon: IconPrizes,
      breadcrumbs: false,
      menuId: "10",
    },
    {
      id: "member-notification",
      title: "Member Notifications",
      type: "item",
      url: "/member-notification",
      icon: IconNotifications,
      breadcrumbs: false,
      menuId: "9",
    },
    {
      id: "member-transfer",
      title: "Members Transfer",
      type: "item",
      url: "/member-transfer",
      icon: IconTransferMember,
      breadcrumbs: false,
      menuId: "14",
    },
  ],
};
//NOTE: URL and id of the children should have the same name
