export class systemRoles {
  static get Admin() {
    return "Admin";
  }
  static get ClubCignallAdmin() {
    return "ClubCignallAdmin";
  }
  static get FranchiseeAdmin() {
    return "FranchiseeAdmin";
  }
  static get StoreRep() {
    return "StoreBDR";
  }
  static get SuperAdmin() {
    return "SuperAdmin";
  }
}

// export class systemAccess {
//   static get Edit() {
//     return "Edit";
//   }
//   static get View() {
//     return "View";
//   }
// }

// export class checkRoleAccess {
//   static HasAccessToStores(role, module, access) {
//     if (role === systemRoles.StoreRep) {
//       if (access === systemAccess.Edit) return false;
//       else if (access === systemAccess.View) return true;
//     } else if (role === systemRoles.SuperAdmin) {
//       return true;
//     }
//     return false;
//   }
// }
