import {
  GppGoodOutlined as IconShieldCheck,
  SettingsOutlined as IconSettings,
} from "@mui/icons-material";

//-----------------------|| APPLICATION MENU ITEMS ||-----------------------//

export const settings = {
  id: "system-config",
  title: "System Configuration",
  type: "group",
  children: [
    {
      id: "system-admins",
      title: "Admins",
      type: "item",
      url: "/admins",
      icon: IconShieldCheck,
      breadcrumbs: false,
      menuId: "12",
    },
    {
      id: "system-settings-header",
      title: "Settings",
      type: "collapse",
      icon: IconSettings,
      menuId: "13",
      children: [
        {
          id: "system-settings",
          title: "System Settings",
          type: "item",
          url: "/system-settings",
          breadcrumbs: false,
        },
        {
          id: "notification-templates",
          title: "Templates",
          type: "item",
          url: "/notification-templates",
          breadcrumbs: false,
        },
      ],
    },
  ],
};
